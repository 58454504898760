/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { DynamicFormValueControlModel, DynamicFormValueControlModelConfig } from './dynamic-form-value-control.model'
import { DynamicFormControlLayout } from './misc/dynamic-form-control-layout.model'
import { isBoolean, isNumber } from '../utils/core.utils'

export interface DynamicInputControlModelConfig<T> extends DynamicFormValueControlModelConfig<T> {
  autoComplete?: string
  autoFocus?: boolean
  maxLength?: number
  minLength?: number
  placeholder?: string
  prefix?: string
  readOnly?: boolean
  spellCheck?: boolean
  suffix?: string
  displayStrengthMeter?: boolean
}

export abstract class DynamicInputControlModel<T> extends DynamicFormValueControlModel<T> {
  autoComplete: string
  autoFocus: boolean
  maxLength: number | null
  minLength: number | null
  placeholder: string
  prefix: string | null
  readOnly: boolean
  spellCheck: boolean
  suffix: string | null
  displayStrengthMeter: boolean

  protected constructor(config: DynamicInputControlModelConfig<T>, layout?: DynamicFormControlLayout) {
    super(config, layout)

    this.autoComplete = config.autoComplete || 'on'
    this.autoFocus = isBoolean(config.autoFocus) ? config.autoFocus : false
    this.maxLength = isNumber(config.maxLength) ? config.maxLength : null
    this.minLength = isNumber(config.minLength) ? config.minLength : null
    this.placeholder = config.placeholder || ''
    this.prefix = config.prefix || null
    this.readOnly = isBoolean(config.readOnly) ? config.readOnly : false
    this.spellCheck = isBoolean(config.spellCheck) ? config.spellCheck : false
    this.suffix = config.suffix || null
    this.displayStrengthMeter = config.displayStrengthMeter
  }
}
