/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export const prospectShortLinkData = {
  redirectUrl: 'http://localhost:4200/de/de_DE/confirm/_prospect/asd',
  shortLinkId: '_prospect',
}

export const ownerShortLinkData = {
  redirectUrl: 'http://localhost:4200/de/de_DE/confirm/_owner/asd',
  shortLinkId: '_owner',
}

export const ownerMobileShortLinkData = {
  redirectUrl: 'http://localhost:4200/de/de_DE/confirm/_ownermobile/abc',
  shortLinkId: '_owner_mobile',
}

export const vehicleShortLinkData = {
  redirectUrl: 'http://localhost:4200/de/de_DE/confirm/_vehicle/asd',
  shortLinkId: '_vehicle',
}

export const simplifiedAddVehicleShortLinkData = {
  redirectUrl: 'http://localhost:4200/de/de_DE/confirm/_sav/asd',
  shortLinkId: '_sav',
}

export const simplifiedAddVehicleMobileShortLinkData = {
  redirectUrl: 'http://localhost:4200/de/de_DE/confirm/_sav/asd',
  shortLinkId: '_sav',
}

export const trackYourDreamShortLinkData = {
  redirectUrl: 'http://localhost:4200/de/de_DE/confirm/_tyd/asd',
  shortLinkId: '_tyd',
}
export const alreadyConfirmedShortLinkData = {
  redirectUrl: 'http://localhost:4200/de/de_DE/confirm/_alreadyConfirmed/asd',
  shortLinkId: '_alreadyConfirmed',
}
