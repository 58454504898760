<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<footer class="flex flex-wrap bg-dark text-dark_primary justify-center p-s select-none self-end">
    <div class="mt-s ml-m mr-m mb-m s:ml-xxl s:mr-xxl">
        <span class="mr-xs">{{ 'ANGULAR_COMMON.FOOTER.COPYRIGHT_YEAR' | translate: { copyrightYear: year } }}</span>
        @for (signupFooterLink of footerLinks; track signupFooterLink) {
            <span class="mr-xs">
        <p-link-pure
                [theme]="'dark'"
                [icon]="'none'"
                [href]="signupFooterLink.url"
                [underline]="true"
                [target]="'_blank'"
                [aria]="{ 'aria-label': 'ANGULAR_COMMON.A11Y.EXTERNAL_LINK' | translate: { link: signupFooterLink.title } }"
                target="_self"
        >
          {{ signupFooterLink.title }}.
        </p-link-pure>
      </span>
        }
        @if (showFooterLinksChina) {
            <span class="mt-s mr-xs">
        <p-link-pure
                [theme]="'dark'"
                [icon]="'none'"
                [href]="'https://www.miibeian.gov.cn'"
                [underline]="true"
                [target]="'_blank'"
                [aria]="{ 'aria-label': 'ANGULAR_COMMON.A11Y.EXTERNAL_LINK' | translate: { link: '沪ICP备10013326号' } }"
                target="_self"
        >
          沪ICP备10013326号.
        </p-link-pure>
      </span>
        } @else if (showFooterLinksUS) {
            <span class="mt-s mr-xs">
        <p-link-pure
                [theme]="'dark'"
                [icon]="'none'"
                [href]="'https://www.porsche.com/usa/accessibilitystatement'"
                [underline]="true"
                [target]="'_blank'"
                [aria]="{
            'aria-label': 'ANGULAR_COMMON.A11Y.EXTERNAL_LINK' | translate: { link: 'ANGULAR_COMMON.FOOTER.ACCESSIBILITY' | translate },
          }"
                target="_self"
        >
          {{ 'ANGULAR_COMMON.FOOTER.ACCESSIBILITY' | translate }}.
        </p-link-pure>
      </span>
        }
        @if (languageChooserEnabled && availableLanguages.length > 1) {
            <div class="flex flex-row flex-grow mt-s">
                <p-select-wrapper class="w-full s:w-1/2 m:w-1/3" theme="dark" [label]="'ANGULAR_COMMON.FOOTER.LANGUAGE_CHOOSER_LABEL' | translate">
                    <select [(ngModel)]="currentLanguage" (ngModelChange)="languageChanged.emit(currentLanguage)">
                        @for (language of availableLanguages; track $index) {
                            <option [value]="language.value">{{ language.title }}</option>
                        }
                    </select>
                </p-select-wrapper>
            </div>
        }
    </div>
</footer>
