/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { getTrackingLayer } from './data-handler'
import { Injectable } from '@angular/core'
import { OneGaData } from './one-ga-types'

@Injectable({
  providedIn: 'root',
})
export class OneGaTrackHandler {
  public track(...data: OneGaData[]) {
    getTrackingLayer().push(...data)
  }
}
