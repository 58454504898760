/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, Inject, Input } from '@angular/core'
import { TransactionType } from '../../enums/transaction-type'
import { Select, Store } from '@ngxs/store'
import { SignupService } from '../../services/signup/signup.service'
import { WINDOW } from '../../services/utils'
import { ConfigService } from '../../services/config/config.service'
import { TranslateService } from '@ngx-translate/core'
import { ConfirmationSelectors } from '../../state/confirmation/confirmation.selectors'
import { Observable } from 'rxjs'
import { Confirmation } from '../../state/confirmation/confirmation.actions'
import RequestOTP = Confirmation.RequestOTP
import { LegalCondition } from '../../models/legal-conditions'

@Component({
  selector: 'myprofile-signup-ui-request-sms-code',
  templateUrl: './request-sms-code.component.html',
})
export class RequestSmsCodeComponent {
  @Input() mobile: string
  @Input() currentPageName: string
  @Input() legalConditions: LegalCondition[]

  @Select(ConfirmationSelectors.transactionType) public readonly transactionType$: Observable<TransactionType>
  @Select(ConfirmationSelectors.otpRequested) public readonly otpRequested$: Observable<boolean>
  @Select(ConfirmationSelectors.otpRequestBlocked) public readonly otpRequestedBlocked$: Observable<boolean>

  public readonly TransactionType = TransactionType
  public openBanner: boolean

  constructor(
    @Inject(WINDOW) private _window: Window,
    private _signupService: SignupService,
    private readonly store: Store,
    private _translateService: TranslateService,
    private _configService: ConfigService,
  ) {
    this.otpRequestedBlocked$.subscribe((blocked) => {
      if (blocked) {
        this.openBanner = true
        setTimeout(() => (this.openBanner = false), 8000)
      }
    })
  }

  get isAddVehicleLeanActivation() {
    return (
      this._signupService.isLeanActivationCountry &&
      [
        TransactionType.ADD_CONNECT_VEHICLE,
        TransactionType.ADD_NON_CONNECT_VEHICLE,
        TransactionType.SIMPLIFIED_ADD_CONNECT_VEHICLE,
        TransactionType.SIMPLIFIED_ADD_NON_CONNECT_VEHICLE,
      ].includes(this._signupService.transactionType)
    )
  }

  get leanActivationText() {
    const translated = this._translateService.instant('signup.activateAccount.addVehicle.leanActivation.legalText')
    return translated
      .replace(/{{tacLink}}/, this.legalConditions?.find((lc) => lc.type === 'TAC')?.linkExt)
      .replace(/{{touLink}}/, this.legalConditions?.find((lc) => lc.type === 'TOU')?.linkExt)
      .replace(/{{dpgLink}}/, this.legalConditions?.find((lc) => lc.type === 'DPG')?.linkExt)
  }

  goToManualAddVehicleProcess() {
    const { vehicle } = this._signupService.transactionData
    this._window.location.href = this._configService.getExternalUrl('portalAddVehicle') + '/' + vehicle.vin
  }

  public onRequestOTP(resend = false) {
    this.store.dispatch(new RequestOTP(resend))
  }
}
