/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { DynamicFormControlModel } from '../model/dynamic-form-control.model'
import { isObject } from '../utils/core.utils'

export enum DynamicFormControlEventType {
  Blur = 'blur',
  Change = 'change',
  Focus = 'focus',
}

export interface DynamicFormControlEvent {
  $event: Event | FocusEvent | DynamicFormControlEvent | any
  control: UntypedFormControl
  group: UntypedFormGroup
  model: DynamicFormControlModel
  type: string
}

export function isDynamicFormControlEvent($event: any): $event is DynamicFormControlEvent {
  // eslint-disable-next-line no-prototype-builtins
  return isObject($event) && $event.hasOwnProperty('$event')
}
