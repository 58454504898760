/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { browser } from './browser'
import { getGtmDataLayer, getPagDataLayer } from './data-handler'

export const addScript = {
  addGtmScript: (dataLayerContainerId: string) => {
    // We need to initialize pagData array in window as
    // scripts loaded trough gtm rely on them to exist
    getPagDataLayer()
    // eslint-disable-next-line camelcase
    getGtmDataLayer().push({ consentMode_activated: true })
    getGtmDataLayer().push({
      'gtm.start': new Date().getTime(),
      event: 'gtm.js',
    })
    const src = `https://www.googletagmanager.com/gtm.js?id=${dataLayerContainerId}&l=oneGa`
    browser.addScript(src)
  },
}
