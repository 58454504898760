/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ErrorHandler, Injectable } from '@angular/core'
import { NewRelicService } from "../new-relic/new-relic-service"

@Injectable({
  providedIn: 'root',
})
export class NewRelicErrorHandler implements ErrorHandler {
  constructor(private readonly newRelicService: NewRelicService) {}

  handleError(error: Error): void {
    this.newRelicService.noticeError(error)
  }
}
