/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, Component, Input } from '@angular/core'
import { BannerState } from '@porsche-design-system/components-angular'

@Component({
  selector: 'myprofile-signup-ui-notification-banner',
  templateUrl: './notification-banner.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class NotificationBannerComponent {
  @Input() isOpen: boolean
  @Input() state: BannerState
  @Input() heading: string
  @Input() message: string
}
