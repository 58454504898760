/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, Inject, Input } from '@angular/core'
import { Select, Store } from '@ngxs/store'
import { ConfirmationSelectors } from '../../state/confirmation/confirmation.selectors'
import { Observable } from 'rxjs'
import { SignupService } from '../../services/signup/signup.service'
import { UntypedFormGroup } from '@angular/forms'
import { PidxGoogleTagManagerIntegrationService } from '../../services/google-tag-manager/pidx-google-tag-manager-integration.service'
import { Confirmation } from '../../state/confirmation/confirmation.actions'
import { LegalCondition } from '../../models/legal-conditions'
import { DynamicFormLayout, DynamicFormModel } from '@myprofile-signup-ui/myprofile-dynamic-forms'
import { FormValidationService } from '../../services/form/form-validation.service'
import { FormService } from '../../services/form/form.service'
import SetPassword = Confirmation.SetPassword
import { ConfirmationStep } from '../../state/confirmation-step.enum'
import { filter } from 'rxjs/operators'
import { WINDOW } from '../../services/utils'

@Component({
  selector: 'myprofile-signup-ui-set-password',
  templateUrl: './set-password.component.html',
})
export class SetPasswordComponent {
  @Input() formGroup: UntypedFormGroup
  @Input() model: DynamicFormModel
  @Input() layout: DynamicFormLayout
  @Input() legalConditions: LegalCondition[]
  @Input() steps: any[]
  @Input() currentStep: ConfirmationStep

  @Select(ConfirmationSelectors.currentStep) public readonly currentStep$: Observable<ConfirmationStep>
  @Select(ConfirmationSelectors.confirmationLoading) public readonly confirmationLoading$: Observable<boolean>
  @Select(ConfirmationSelectors.confirmationCode) public readonly confirmationCode$: Observable<string>

  private confirmationCode: string

  constructor(
    @Inject(WINDOW) private _window: Window,
    private _tagManagerIntegrationService: PidxGoogleTagManagerIntegrationService,
    private readonly store: Store,
    private _signupService: SignupService,
    private _formService: FormService,
  ) {
    this.currentStep$.pipe(filter((step) => step === ConfirmationStep.SET_PASSWORD)).subscribe(() => {
      // from a ux perspective it makes sense to auto-focus the first input
      this._window.setTimeout(() => {
        this._setupTrackingForLinkouts()
      }, 100)
    })

    this.confirmationCode$.pipe().subscribe((confirmationCode) => {
      this.confirmationCode = confirmationCode
    })
  }

  private _setupTrackingForLinkouts() {
    if (this._window.document.querySelector('#tac-checkboxWrapper') !== null) {
      const element = this._window.document
        .querySelector('#tac-checkboxWrapper')
        .getElementsByTagName('span')
        .item(0)
        .getElementsByTagName('a')
        .item(0)
      const href = element.getAttribute('href')
      element.onclick = (event) => this.onClickHandling(event, 'GTC', href)
    }
    if (this._window.document.querySelector('#dpg_dc-checkboxWrapper') !== null) {
      const element = this._window.document
        .querySelector('#dpg_dc-checkboxWrapper')
        .getElementsByTagName('span')
        .item(0)
        .getElementsByTagName('a')
        .item(0)
      const href = element.getAttribute('href')
      element.onclick = (event) => this.onClickHandling(event, 'ToU', href)
    }
  }

  private onClickHandling(_: MouseEvent, clickElementName: string, href: string) {
    this._tagManagerIntegrationService.triggerClickTracking({
      clickElementType: 'link',
      clickElementId: 'linkout',
      clickElementName: clickElementName,
      pageName: 'sign_up/confirm',
      eventAction: 'PAGMyPorsche_SignUpSetPW_Click',
      targetUrl: href,
    })
  }

  get lastStepLabel() {
    switch (this._signupService.transactionData.type) {
      default:
      case 'OWNER':
        return 'signup.lastStep.owner.button.label'
      case 'VEHICLE':
        return 'signup.lastStep.vehicle.button.label'
    }
  }

  public async submitSetPasswordForm() {
    FormValidationService.triggerFormValidationErrors(this.formGroup)
    if (this.formGroup.invalid) {
      this._tagManagerIntegrationService.trackFormError(this.formGroup, {
        pageName: 'sign_up/confirm',
        eventAction: 'PAGMyPorsche_SignUpSetPWError_Load',
      })
      return
    }

    this.formGroup.disable({ emitEvent: false })

    const preparedPayload = this._formService.prepareFormPayload(this.formGroup, this.legalConditions)

    this.store.dispatch(new SetPassword(this.formGroup.get('password')?.value, this.confirmationCode, preparedPayload))
  }

  public isLastStep(currentStep: ConfirmationStep) {
    const maxIndex = this.steps.length - 1
    const index = this.steps.indexOf(currentStep)

    return maxIndex === index
  }
}
