/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { OverlayContainer, OverlayModule } from '@angular/cdk/overlay'
import { CommonModule } from '@angular/common'
import { PidxTooltipContentComponent } from './pidx-tooltip-content.component'
import { PidxTooltipDirective } from './pidx-tooltip-directive.component'
import { PidxIconWTooltipComponent } from './pidx-icon-w-tooltip.component'
import { PidxOverlayContainer } from '../pidx-overlay-container/pidx-overlay-container'

@NgModule({
  imports: [OverlayModule, CommonModule],
  declarations: [PidxTooltipContentComponent, PidxTooltipDirective, PidxIconWTooltipComponent],
  providers: [
    {
      provide: OverlayContainer,
      useClass: PidxOverlayContainer,
    },
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  exports: [PidxTooltipDirective, PidxIconWTooltipComponent],
})
export class PidxTooltipModule {}
