/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core'

@Injectable({
  providedIn: 'root',
})
export class PidxDeviceDetectionService {
  /* Detection according to
    @see Detect Mobile: https://stackoverflow.com/a/25394023/227187
    @see Why exclude MSStream https://stackoverflow.com/a/9039885/1262901
  */
  public isMobileDevice(): boolean {
    if (!navigator || !navigator.userAgent || !window) {
      // can't detect which device
      return false
    }
    // tslint:disable-next-line:max-line-length
    return (
      /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/.test(navigator.userAgent) &&
      !(window as any).MSStream
    )
  }

  public isMobilePhoneDevice(): boolean {
    if (!navigator || !navigator.userAgent || !window) {
      // can't detect which device
      return false
    }
    // tslint:disable-next-line:max-line-length
    return /Android|webOS|iPhone|BlackBerry|IEMobile|Opera Mini|Mobile|mobile|CriOS/.test(navigator.userAgent) && !(window as any).MSStream
  }
}
