/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export function isBoolean(value: any): value is boolean {
  return typeof value === 'boolean'
}

// eslint-disable-next-line @typescript-eslint/ban-types
export function isFunction(value: any): value is Function {
  return typeof value === 'function'
}

export function isNumber(value: any): value is number {
  return typeof value === 'number'
}

export function isObject(value: any): value is object {
  return typeof value === 'object' && value !== null
}

export function isString(value: any): value is string {
  return typeof value === 'string'
}
