/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component, OnInit } from '@angular/core'
import { PictureService } from '../../services/picture/picture-service.service'

@Component({
  selector: 'myprofile-signup-ui-layout-default',
  templateUrl: './layout-default.component.html',
  styleUrls: ['./layout-default.component.scss'],
})
export class LayoutDefaultComponent implements OnInit {

  public gradientColor: string

  constructor(private _pictureService: PictureService) {}

  ngOnInit(): void {
    this._pictureService.getPictureMetaData().subscribe( {
      next: (pictureMeta) => {
        this.gradientColor = this.hexToHsl(pictureMeta.averageColor)
      }
    })
  }

  private hexToHsl(hex: string) {
    // Convert hex to RGB first
    let r = 0, g = 0, b = 0
    if (hex.length === 4) {
      r = parseInt(hex[1] + hex[1], 16)
      g = parseInt(hex[2] + hex[2], 16)
      b = parseInt(hex[3] + hex[3], 16)
    } else if (hex.length === 7) {
      r = parseInt(hex[1] + hex[2], 16)
      g = parseInt(hex[3] + hex[4], 16)
      b = parseInt(hex[5] + hex[6], 16)
    } else {
      throw new Error("Invalid HEX color format.")
    }

    // Convert RGB to HSL
    r /= 255
    g /= 255
    b /= 255
    const cmin = Math.min(r, g, b),
      cmax = Math.max(r, g, b),
      delta = cmax - cmin
    let h = 0,
      s = 0,
      l = 0

    if (delta === 0) {
      h = 0
    } else if (cmax === r) {
      h = ((g - b) / delta) % 6
    } else if (cmax === g) {
      h = (b - r) / delta + 2
    } else {
      h = (r - g) / delta + 4
    }

    h = Math.round(h * 60)
    if (h < 0) {
      h += 360
    }

    l = (cmax + cmin) / 2
    s = delta === 0 ? 0 : delta / (1 - Math.abs(2 * l - 1))
    s = +(s * 100).toFixed(1)
    // We set the light value to a fixed number, because we want to have the color to ligther in general
    l = 80

    return `hsl(${h},${s}%,${l}%)`
  }

}
