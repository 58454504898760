<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<p-heading tag="h1" class="text-balance">
    {{ 'signup.owner.confirmation.introduction.headline' | translate }}
</p-heading>

<div class="w-full flex flex-col">
    <p-text size="{base: 'small', m: 'medium' }" class="text-balance mt-static_m s:mt-static_l">
        {{ 'signup.owner.confirmation.introduction.subline' | translate }}
    </p-text>

    <p-heading tag="h2" size="small" class="mt-static_xxl xs:mt-static_l order-last xs:order-none">
        {{ 'signup.owner.confirmation.introduction.advantages.label' | translate }}
    </p-heading>

    <ul class="mt-static_m space-y-static_s pl-0 order-last xs:order-none">
        @for (advantage of [
            'signup.owner.confirmation.introduction.advantages.vehicleStatus',
            'signup.owner.confirmation.introduction.advantages.connectAccess',
            'signup.owner.confirmation.introduction.advantages.exclusive']; track advantage) {
            <li class="list-none">
                <p-text size="small">
                    <p-icon class="mr-xs" name="check" [aria]="{ 'aria-label': 'Check icon' }"/>
                    <span>{{ advantage | translate }}</span>
                </p-text>
            </li>
        }
    </ul>

    <div>
        <p-button
                (click)="onContinue()"
                class="mt-static_l w-full xs:w-auto"
                variant="primary"
                data-e2e="confirmation-introduction-submit-button"
                [myprofileSignupUiTrack]="{
          clickElementType: 'button',
          clickElementId: 'call_to_action',
          clickElementName: 'confirmation_introduction_continue',
          pageName: currentPageName,
          eventAction: currentEventAction
        }"
        >
            {{ 'signup.owner.confirmation.introduction.button.label' | translate }}
        </p-button>
    </div>
</div>
