/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core'
import { registerLocaleData } from '@angular/common'
import { map } from 'rxjs/operators'
import { from, Observable } from 'rxjs'

@Injectable()
export class CustomLocaleLoaderService {
  load(locale: string): Observable<string> {
    /**
     * important: webpack include has to be updated as soon as
     * any additional locale is needed
     * We need to use a relative path to node_modules for angular 13+ because of a change in angular locale and a bug in webpack
     * https://github.com/angular/angular-cli/issues/22088
     */
    /* eslint-disable */
    return from(
      import(
        /* webpackChunkName: "angular-locale-" */
        /* webpackMode: "lazy-once" */
        /* webpackInclude: /\@angular[\/\\]common[\/\\]locales[\/\\](cs|da|de|en-GB|en|es|et|fi|fr|it|ja|ko|lt|lv|nl|nn|nb|pl|pt|ru|sv|tr|zh-Hans|zh-Hant).mjs$/ */
        /* webpackExclude: /\@angular[\/\\]common[\/\\]locales[\/\\](extra|global)\// */
        `../../../../../node_modules/@angular/common/locales/${locale}.mjs`
      ),
    ).pipe(
      map((localeData) => {
        registerLocaleData(localeData.default)
        return locale
      }),
    )
    /* eslint-enable */
  }
}
