/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { TransactionType } from '../../enums/transaction-type'
import { ConfirmationType } from '../../enums/confirmation-type'

// eslint-disable-next-line @typescript-eslint/no-namespace
export namespace Confirmation {
  export class RequestOTP {
    static readonly type = '[Confirmation] Request OTP'

    constructor(public resend: boolean) {}
  }

  export class ValidateOTP {
    static readonly type = '[Confirmation] Validate OTP'

    constructor(public otp: string) {}
  }

  export class ProceedConfirmation {
    static readonly type = '[Confirmation] ProceedConfirmation'
  }

  export class Init {
    static readonly type = '[Confirmation] Init'

    constructor(public transactionType: TransactionType, public type: ConfirmationType, public baseModel: string | null) {}
  }

  export class SetPassword {
    static readonly type = '[Confirmation] Set Password'

    constructor(public password: string, public otp: string, public preparedPayload: any) {}
  }

  export class ActivateVehicle {
    static readonly type = '[Confirmation] Activate Vehicle'

    constructor(public otp: string) {}
  }

  export class ActivateOwner {
    static readonly type = '[Confirmation] Activate Owner'
  }

  export class SetSPIN {
    static readonly type = '[Confirmation] Set SPIN'

    constructor(public spin: string) {}
  }

  export class ActivationSuccessful {
    static readonly type = '[Confirmation] Activation Successful'
  }
}
