<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->
<div *ngIf="isLoading">
  <div class="h-screen flex flex-row justify-center">
    <div class="self-center">
      <div class="flex flex-col items-center">
        <div>
          <p-spinner size="{ base: 'medium', l: 'large' }"></p-spinner>
        </div>
        <div>
          <p-text size="{ base: 'small', l: 'medium' }" align="center">
            {{ labelKey | translate }}
          </p-text>
        </div>
      </div>
    </div>
  </div>
</div>
