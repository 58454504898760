/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Inject, Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { BackendRoutes } from '../../constants'
import { forkJoin, lastValueFrom, Observable, of } from 'rxjs'
import { AppConfig, AppConfigBasePartial, FooterExternalLink } from './config.interface'
import { map, switchMap } from 'rxjs/operators'
import { environment } from '../../../environments/environment'
import { WINDOW } from '../utils'

@Injectable({
  providedIn: 'root',
})
export class ConfigLoaderService {
  constructor(private _httpClient: HttpClient, @Inject(WINDOW) private _window: Window) {}

  get localisationBaseUrl(): string {
    return this._window.location.hostname.includes('.cn') ? environment.urls.localisationCN : environment.urls.localisation
  }

  public getConfig(locale: string, country: string): Observable<AppConfig> {
    if (locale.length === 2) {
      locale = locale.toLowerCase() + '_' + country.toUpperCase()
    }
    let updatedLocale = ''
    if (locale.includes('-')) {
      const [l1, l2] = locale.split('-')
      updatedLocale = `${l1.toLowerCase()}_${l2.toUpperCase()}`
    } else {
      const [l1, l2] = locale.split('_')
      updatedLocale = `${l1.toLowerCase()}_${l2.toUpperCase()}`
    }
    const configBasePath = BackendRoutes.CONFIG_BASE.replace(':country', country.toLowerCase())
      .replace(':locale', updatedLocale)
      .replace(':stage', environment.production ? 'p' : 'pp')
      .replace(':version', environment.production ? 'RELEASE' : 'LATEST')

    const configBaseUrl = `${this.localisationBaseUrl}${configBasePath}`

    return this._httpClient.get<AppConfigBasePartial>(configBaseUrl).pipe(
      switchMap((configBase) => {
        const footerLegalBasePath = BackendRoutes.CONFIG_FOOTER_LEGAL.replace(':locale', configBase.localeInformation.translationFileName)
          .replace(':stage', environment.production ? 'p' : 'pp')
          .replace(':version', environment.production ? 'RELEASE' : 'LATEST')

        const footerLegalBaseUrl = `${this.localisationBaseUrl}${footerLegalBasePath}`
        return forkJoin([of(configBase), this._httpClient.get<FooterExternalLink[]>(footerLegalBaseUrl)])
      }),
      map(([configBase, footerLegalLinks]) => {
        return {
          ...configBase,
          footerLinks: {
            meta: footerLegalLinks,
          },
        }
      }),
    )
  }

  public async getCountryLocaleMap(): Promise<Map<string, { default: string; locales: string[] }>> {
    const path =
      this.localisationBaseUrl + 'country-locale-map/' + (environment.production ? 'RELEASE' : 'LATEST') + '/country-locale-map.json'
    return lastValueFrom(this._httpClient.get<Map<string, { default: string; locales: string[] }>>(path))
  }
}
