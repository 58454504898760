<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<p-button-pure
  icon="close"
  (click)="showCancelActivationModal()"
  class="mt-static_l"
  *ngIf="(currentStep$ | async) !== ConfirmationSteps.SET_SPIN"
>
  <ng-container *ngIf="(type$ | async) !== 'VEHICLE' && (type$ | async) !== 'SECONDARY_VEHICLE'; else addVehicleCancelButton">
    {{ 'signup.activateAccount.cancelActivation.button.label' | translate }}
  </ng-container>
  <ng-template #addVehicleCancelButton>
    {{ 'pida.addVehicle.confirmationCode.btnCancelActivation' | translate }}
  </ng-template>
</p-button-pure>

<p-modal [open]="showModal" (close)="toggleModal('close')" [heading]="modalHeader | translate">
  <ng-container *ngIf="(type$ | async) !== 'VEHICLE' && (type$ | async) !== 'SECONDARY_VEHICLE'; else addVehicleModal">
    <p-text>
      {{ 'signup.activateAccount.cancelActivation.modal.body' | translate }}
    </p-text>
    <p-button-group class="mt-static_l">
      <p-button (click)="cancelActivation()">
        {{ 'signup.activateAccount.cancelActivation.modal.button.cancel' | translate }}
      </p-button>
      <p-button [variant]="'tertiary'" (click)="toggleModal('continue_activation')">
        {{ 'signup.activateAccount.cancelActivation.modal.button.continue' | translate }}
      </p-button>
    </p-button-group>
  </ng-container>
  <ng-template #addVehicleModal>
    <p-text>
      {{ 'pida.addVehicle.cancelActivation.modal.body' | translate }}
    </p-text>
    <p-button-group class="mt-static_l">
      <p-button (click)="cancelActivation()">
        {{ 'pida.addVehicle.confirmationCode.btnCancelActivation' | translate }}
      </p-button>
      <p-button [variant]="'tertiary'" (click)="toggleModal('continue_activation')">
        {{ 'pida.addVehicle.confirmationCode.btnResumeActivation' | translate }}
      </p-button>
    </p-button-group>
  </ng-template>
</p-modal>
