/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Inject, Injectable } from '@angular/core'
import { Observable } from 'rxjs'
import { v4 as uuid } from 'uuid'

export const XTRACE_ID_PREFIX = 'XTRACE_ID_PREFIX'

@Injectable()
export class XTraceInterceptor implements HttpInterceptor {
  constructor(@Inject(XTRACE_ID_PREFIX) private _traceIdPrefix: string) {}

  public intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestClone = request.clone({
      setHeaders: {
        'X-TRACE-ID': `${this._traceIdPrefix}-${uuid()}`,
      },
    })
    return next.handle(requestClone)
  }
}
