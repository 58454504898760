<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

@if (isOpen) {
  <p-banner [open]="isOpen" [state]="state" [dismissButton]="false">
    <span slot="heading">{{ heading | translate }}</span>
    <span slot="description">{{ message | translate }}</span>
  </p-banner>
}
