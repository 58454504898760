/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { Observable } from 'rxjs'
import { BackendRoutes } from '../../constants'
import { ConfigService } from '../config/config.service'
import { UntypedFormGroup } from '@angular/forms'

export type LayoutSchemaType =
  | 'prospect-registration'
  | 'prospect-confirmation'
  | 'organisation-registration'
  | 'owner-confirmation'
  | 'secondary-confirmation'
  | 'track-your-dream-confirmation'

@Injectable({
  providedIn: 'root',
})
export class FormService {
  constructor(private _httpClient: HttpClient, private _configService: ConfigService) {}

  public getSchema(country: string, locale: string, type: LayoutSchemaType = 'prospect-registration'): Observable<any> {
    const path = BackendRoutes.FORM__SCHEMA__SIGNUP.replace(':type', type)
      .replace(':country', country.toLowerCase())
      .replace(':locale', locale)
      .replace(':version', this._configService.isProduction() ? 'RELEASE' : 'LATEST')
    return this._httpClient.get(`${this._configService.getLocalisationBaseUrl()}${path}`)
  }

  public getLayout(country: string, type: LayoutSchemaType = 'prospect-registration'): Observable<any> {
    const path = BackendRoutes.FORM__LAYOUT__SIGNUP.replace(':type', type)
      .replace(':country', country.toLowerCase())
      .replace(':stage', this._configService.isProduction() ? 'p' : 'pp')
      .replace(':version', this._configService.isProduction() ? 'RELEASE' : 'LATEST')

    return this._httpClient.get(`${this._configService.getLocalisationBaseUrl()}${path}`)
  }

  public extendSchemaWithLegalConditionLinks(schema: any, legalConditions: any = []): any {
    return schema.map((schemaItem) => {
      switch (schemaItem.id) {
        case 'tac':
          schemaItem.label = schemaItem.label.replace(/{{tacLink}}/, legalConditions.find((lc) => lc.type === 'TAC')?.linkExt)
          schemaItem.openURLOnClick = schemaItem.openURLOnClick?.replace(
            /{{link}}/,
            legalConditions.find((lc) => lc.type === 'TAC')?.linkExt,
          )
          break
        case 'dpg':
          schemaItem.label = schemaItem.label.replace(/{{dpgLink}}/, legalConditions.find((lc) => lc.type === 'DPG')?.linkExt)
          schemaItem.openURLOnClick = schemaItem.openURLOnClick?.replace(
            /{{link}}/,
            legalConditions.find((lc) => lc.type === 'DPG')?.linkExt,
          )
          break
        case 'dpg_2':
          schemaItem.label = schemaItem.label.replace(/{{dpgLink}}/, legalConditions.find((lc) => lc.type === 'DPG_2')?.linkExt)
          schemaItem.openURLOnClick = schemaItem.openURLOnClick?.replace(
            /{{link}}/,
            legalConditions.find((lc) => lc.type === 'DPG_2')?.linkExt,
          )
          break
        case 'dpg_3':
          schemaItem.label = schemaItem.label.replace(/{{dpgLink}}/, legalConditions.find((lc) => lc.type === 'DPG_3')?.linkExt)
          schemaItem.openURLOnClick = schemaItem.openURLOnClick?.replace(
            /{{link}}/,
            legalConditions.find((lc) => lc.type === 'DPG_3')?.linkExt,
          )
          break
        case 'dpg_dc':
          schemaItem.label = schemaItem.label.replace(/{{dpgDcLink}}/, legalConditions.find((lc) => lc.type === 'DPG_DC')?.linkExt)
          schemaItem.openURLOnClick = schemaItem.openURLOnClick?.replace(
            /{{link}}/,
            legalConditions.find((lc) => lc.type === 'DPG_DC')?.linkExt,
          )
          break
        case 'legalText':
          schemaItem.label = schemaItem.label
            .replace(/{{tacLink}}/, legalConditions.find((lc) => lc.type === 'TAC')?.linkExt)
            .replace(/{{touLink}}/, legalConditions.find((lc) => lc.type === 'TOU')?.linkExt)
            .replace(/{{dpgLink}}/, legalConditions.find((lc) => lc.type === 'DPG')?.linkExt)
      }
      return schemaItem
    })
  }

  // Structure the data correctly so that the api can make sense of it
  public prepareFormPayload(formGroup: UntypedFormGroup, legalConditions: any = []): any {
    const result: any = {}

    Object.entries(formGroup.controls).forEach(([ctrlKey, ctrlValue]) => {
      if (ctrlValue instanceof UntypedFormGroup) {
        Object.entries(ctrlValue.value).forEach(([key, value]) => {
          result[key] = value
        })
      } else {
        switch (ctrlKey) {
          case 'tac':
          case 'dpg':
          case 'dpg_2':
          case 'dpg_3':
          case 'dpg_dc':
            if (ctrlValue?.value === true) {
              // Init legal prop lazily in case they are required but not defined yet
              if (result.legal === null || result.legal === undefined) {
                result.legal = {}
              }
              result.legal[ctrlKey] = legalConditions.find((lc) => lc.type === ctrlKey.toUpperCase())?.ciamVersion
            }
            break
          default:
            result[ctrlKey] = ctrlValue.value
        }
      }
    })
    return result
  }
}
