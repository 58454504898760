/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core'
import { Action, State, StateContext } from '@ngxs/store'
import { LegalConditionStateModel } from './legal-condition-state.model'
import { SignupService } from '../../services/signup/signup.service'
import { LegalConditionActions } from './legal-condition.actions'
import { lastValueFrom } from 'rxjs'

import Init = LegalConditionActions.Init

@State<LegalConditionStateModel>({
  name: 'legalCondition',
  defaults: {
    loaded: false,
    loading: false,
    error: false,
    legalConditions: null,
  },
})
@Injectable({
  providedIn: 'root',
})
export class LegalConditionState {
  constructor(private readonly signupService: SignupService) {}

  @Action(Init)
  public async initState(ctx: StateContext<LegalConditionStateModel>) {
    try {
      ctx.patchState({ loading: true })
      const legalConditions = await lastValueFrom(this.signupService.getLegalConditions())
      ctx.patchState({
        legalConditions: legalConditions.reduce(
          (providedLegalConditions, currentLegalCondition) => ({
            ...providedLegalConditions,
            [currentLegalCondition.type]: currentLegalCondition,
          }),
          {},
        ),
      })
    } catch (error) {
      ctx.patchState({ error: true })
    } finally {
      ctx.patchState({ loading: false, loaded: true })
    }
  }
}
