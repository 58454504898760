/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

// function to create a new url based on a url and a relative url e.g. parseUrl('/foo/bar/baz', '../qux'); => /foo/bar/qux
// this helper is present cause of the issue in the angular router: https://github.com/angular/angular/issues/22763
import { InjectionToken } from '@angular/core'

export const parseUrl = (url: string, redirectTo: string) => {
  const urlTokens = url.split('/')
  const redirectToTokens = redirectTo.split('/')

  let token = redirectToTokens.shift()

  while (token) {
    if (token !== '.' && token !== '..') {
      redirectToTokens.unshift(token)
      break
    }

    if (token === '..') {
      urlTokens.pop()
    }

    token = redirectToTokens.shift()
  }

  urlTokens.push(...redirectToTokens)

  return urlTokens.join('/')
}

export const randomId = (length = 16, chars?: string) => {
  let result = ''
  const characters = chars ? chars : 'abcdef0123456789'
  const charactersLength = characters.length
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * charactersLength))
  }
  return result
}

export function setPinCodeFocus(shadowRoot: ShadowRoot) {
  if (shadowRoot) {
    const firstInputElement = shadowRoot.querySelector<HTMLInputElement>(`input`)

    if (firstInputElement) {
      firstInputElement.focus({ preventScroll: true })
    }
  }
}

export const WINDOW = new InjectionToken<WindowWrapper>('window object', {
  providedIn: 'root',
  factory: () => window,
})

export interface WindowWrapper extends Window {
  console: Console // TS 3.9 removed console from window interface
}
