/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import {
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  QueryList,
  ViewChildren,
} from '@angular/core'
import { UntypedFormGroup } from '@angular/forms'
import { DynamicFormControlContainerComponent } from './dynamic-form-control-container.component'
import { DynamicFormControlEvent } from './dynamic-form-control-event'
import { DynamicFormControlModel } from '../model/dynamic-form-control.model'
import { DynamicFormModel } from '../model/dynamic-form.model'
import { DynamicFormLayout } from '../service/dynamic-form-layout.service'
import { DynamicFormComponentService } from '../service/dynamic-form-component.service'

@Component({
  selector: 'dynamic-form',
  templateUrl: './dynamic-form.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DynamicFormComponent implements OnInit, OnDestroy {
  @Input() group: UntypedFormGroup
  @Input() model: DynamicFormModel
  @Input() layout: DynamicFormLayout

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>()
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>()
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus: EventEmitter<DynamicFormControlEvent> = new EventEmitter<DynamicFormControlEvent>()
  @Output() metaDataSelectedEvent: EventEmitter<any> = new EventEmitter<any>()

  @ViewChildren(DynamicFormControlContainerComponent) components: QueryList<DynamicFormControlContainerComponent>

  constructor(
    private changeDetectorRef: ChangeDetectorRef,
    private componentService: DynamicFormComponentService,
  ) {}

  ngOnInit(): void {
    this.componentService.registerForm(this)
  }

  ngOnDestroy(): void {
    this.componentService.unregisterForm(this)
  }

  trackByFn(_index: number, model: DynamicFormControlModel): string {
    return model.id
  }

  onBlur($event: DynamicFormControlEvent) {
    this.blur.emit($event)
  }

  onChange($event: DynamicFormControlEvent) {
    this.change.emit($event)
  }

  onFocus($event: DynamicFormControlEvent) {
    this.focus.emit($event)
  }

  onMetadataSelected($event: any) {
    this.metaDataSelectedEvent.emit($event)
  }

  markForCheck(): void {
    this.changeDetectorRef.markForCheck()

    if (this.components instanceof QueryList) {
      this.components.forEach((component) => component.markForCheck())
    }
  }

  detectChanges(): void {
    this.changeDetectorRef.detectChanges()
  }
}
