/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core'
import { OverlayContainer } from '@angular/cdk/overlay'

@Injectable({ providedIn: 'root' })
export class PidxOverlayContainer extends OverlayContainer {
  protected _createContainer(): void {
    const containerClass = 'pidx-overlay-container'
    const container = this._document.createElement('div')
    container.classList.add(containerClass)

    this._document.body.appendChild(container)
    this._containerElement = container
  }
}
