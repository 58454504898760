/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

/** How the localized language names work:
 * Given the current country is 'ch' and the current locale is 'de_DE'.
 * Switzerland (CH) has four available languages: en_GB, de_DE, it_IT and fr_FR.
 * We want to show the available languages in the currently selected language, in this case:
 * 'Englisch', 'Deutsch', 'Italienisch' and 'Französisch'.
 *
 * Taking the above example, we use the lookup table below:
 * For each available language (en_GB, de_DE, it_IT, fr_FR) in the current country (CH),
 * we only consider the language parts of the available locale and the currently selected locale (de_DE) - for 'en_GB' this is 'en',
 * and get the display name for each language by indexing into `localisedLanguageNames[availableLang][currentLang]`,
 * in this example like this:
 * - en_GB: `localisedLanguageNames[en][de] -> Englisch`
 * - de_DE: `localisedLanguageNames[de][de] -> Deutsch`
 * - it_IT: `localisedLanguageNames[it][de] -> Italienisch`
 * - fr_FR: `localisedLanguageNames[fr][de] -> Französisch`
 *
 * Informally speaking: we want to know how some language (e.g. en) is named in the currently selected language (de).
 */

export const localisedLanguageNames = {
  de: {
    cs: 'němčina',
    da: 'tysk',
    de: 'Deutsch',
    en: 'German',
    es: 'alemán',
    et: 'saksa',
    fi: 'saksa',
    fr: 'allemand',
    it: 'tedesco',
    ja: 'ドイツ語',
    lt: 'vokiečių',
    lv: 'vācu',
    nl: 'Duits',
    no: 'tysk',
    pl: 'niemiecki',
    pt: 'alemão',
    ru: 'немецкий',
    sv: 'tyska',
    tr: 'Almanca',
    zh: '德文',
    ko: '독일어',
  },
  ko: {
    cs: 'korejština',
    da: 'koreansk',
    de: 'Koreanisch',
    en: 'Korean',
    es: 'coreano',
    et: 'korea',
    fi: 'korea',
    fr: 'coréen',
    it: 'coreano',
    ja: '韓国語',
    lt: 'korėjiečių',
    lv: 'korejiešu',
    nl: 'Koreaans',
    no: 'koreansk',
    pl: 'koreański',
    pt: 'coreano',
    ru: 'корейский',
    sv: 'koreanska',
    tr: 'Korece',
    zh: '韩文',
    ko: '한국어',
  },
  en: {
    cs: 'angličtina',
    da: 'engelsk',
    de: 'Englisch',
    en: 'English',
    es: 'inglés',
    et: 'inglise',
    fi: 'englanti',
    fr: 'anglais',
    it: 'inglese',
    ja: '英語',
    lt: 'anglų',
    lv: 'angļu',
    nl: 'Engels',
    no: 'engelsk',
    pl: 'angielski',
    pt: 'inglês',
    ru: 'английский',
    sr: 'Engelski',
    sv: 'engelska',
    tr: 'İngilizce',
    zh: '英文',
    ko: '영어',
  },
  es: {
    cs: 'španělština',
    da: 'spansk',
    de: 'Spanisch',
    en: 'Spanish',
    es: 'español',
    et: 'hispaania',
    fi: 'espanja',
    fr: 'espagnol',
    it: 'spagnolo',
    ja: 'スペイン語',
    lt: 'ispanų',
    lv: 'spāņu',
    nl: 'Spaans',
    no: 'spansk',
    pl: 'hiszpański',
    pt: 'espanhol',
    ru: 'испанский',
    sv: 'spanska',
    tr: 'İspanyolca',
    zh: '西班牙文',
    ko: '스페인어',
  },
  fr: {
    cs: 'francouzština',
    da: 'fransk',
    de: 'Französisch',
    en: 'French',
    es: 'francés',
    et: 'prantsuse',
    fi: 'ranska',
    fr: 'français',
    it: 'francese',
    ja: 'フランス語',
    lt: 'prancūzų',
    lv: 'franču',
    nl: 'Frans',
    no: 'fransk',
    pl: 'francuski',
    pt: 'francês',
    ru: 'французский',
    sv: 'franska',
    tr: 'Fransızca',
    zh: '法文',
    ko: '프랑스어',
  },
  it: {
    cs: 'italština',
    da: 'italiensk',
    de: 'Italienisch',
    en: 'Italian',
    es: 'italiano',
    et: 'itaalia',
    fi: 'italia',
    fr: 'italien',
    it: 'italiano',
    ja: 'イタリア語',
    lt: 'italų',
    lv: 'itāļu',
    nl: 'Italiaans',
    no: 'italiensk',
    pl: 'włoski',
    pt: 'italiano',
    ru: 'итальянский',
    sv: 'italienska',
    tr: 'İtalyanca',
    zh: '意大利文',
    ko: '이탈리아어',
  },
  ja: {
    cs: 'japonština',
    da: 'japansk',
    de: 'Japanisch',
    en: 'Japanese',
    es: 'japonés',
    et: 'jaapani',
    fi: 'japani',
    fr: 'japonais',
    it: 'giapponese',
    ja: '日本語',
    lt: 'japonų',
    lv: 'japāņu',
    nl: 'Japans',
    no: 'japansk',
    pl: 'japoński',
    pt: 'japonês',
    ru: 'японский',
    sv: 'japanska',
    tr: 'Japonca',
    zh: '日文',
    ko: '일본어',
  },
  pt: {
    cs: 'portugalština',
    da: 'portugisisk',
    de: 'Portugiesisch',
    en: 'Portuguese',
    es: 'portugués',
    et: 'portugali',
    fi: 'portugali',
    fr: 'portugais',
    it: 'portoghese',
    ja: 'ポルトガル語',
    lt: 'portugalų',
    lv: 'portugāļu',
    nl: 'Portugees',
    no: 'portugisisk',
    pl: 'portugalski',
    pt: 'português',
    ru: 'португальский',
    sv: 'portugisiska',
    tr: 'Portekizce',
    zh: '葡萄牙文',
    ko: '포르투갈어',
  },
  ru: {
    cs: 'ruština',
    da: 'russisk',
    de: 'Russisch',
    en: 'Russian',
    es: 'ruso',
    et: 'vene',
    fi: 'venäjä',
    fr: 'russe',
    it: 'russo',
    ja: 'ロシア語',
    lt: 'rusų',
    lv: 'krievu',
    nl: 'Russisch',
    no: 'russisk',
    pl: 'rosyjski',
    pt: 'russo',
    ru: 'русский',
    sv: 'ryska',
    tr: 'Rusça',
    zh: '俄文',
    ko: '러시아어',
  },
  zh: {
    cs: 'čínština',
    da: 'kinesisk',
    de: 'Chinesisch',
    en: 'Chinese',
    es: 'chino',
    et: 'hiina',
    fi: 'kiina',
    fr: 'chinois',
    it: 'cinese',
    ja: '中国語',
    lt: 'kinų',
    lv: 'ķīniešu',
    nl: 'Chinees',
    no: 'kinesisk',
    pl: 'chiński',
    pt: 'chinês',
    ru: 'китайский',
    sv: 'kinesiska',
    tr: 'Çince',
    zh: '中文',
    ko: '중국어',
  },
  cs: {
    cs: 'čeština',
    da: 'tjekkisk',
    de: 'Tschechisch',
    en: 'Czech',
    es: 'checo',
    et: 'tšehhi',
    fi: 'tšekki',
    fr: 'tchèque',
    it: 'ceco',
    ja: 'チェコ語',
    lt: 'čekų',
    lv: 'čehu',
    nl: 'Tsjechisch',
    no: 'tsjekkisk',
    pl: 'czeski',
    pt: 'tcheco',
    ru: 'чешский',
    sv: 'tjeckiska',
    tr: 'Çekçe',
    zh: '捷克文',
    ko: '체코어',
  },
  da: {
    cs: 'dánština',
    da: 'dansk',
    de: 'Dänisch',
    en: 'Danish',
    es: 'danés',
    et: 'taani',
    fi: 'tanska',
    fr: 'danois',
    it: 'danese',
    ja: 'デンマーク語',
    lt: 'danų',
    lv: 'dāņu',
    nl: 'Deens',
    no: 'dansk',
    pl: 'duński',
    pt: 'dinamarquês',
    ru: 'датский',
    sv: 'danska',
    tr: 'Danca',
    zh: '丹麦文',
    ko: '덴마크어',
  },
  et: {
    cs: 'estonština',
    da: 'estisk',
    de: 'Estnisch',
    en: 'Estonian',
    es: 'estonio',
    et: 'eesti',
    fi: 'viro',
    fr: 'estonien',
    it: 'estone',
    ja: 'エストニア語',
    lt: 'estų',
    lv: 'igauņu',
    nl: 'Estisch',
    no: 'estisk',
    pl: 'estoński',
    pt: 'estoniano',
    ru: 'эстонский',
    sv: 'estniska',
    tr: 'Estonya Dili',
    zh: '爱沙尼亚文',
    ko: '에스토니아어',
  },
  fi: {
    cs: 'finština',
    da: 'finsk',
    de: 'Finnisch',
    en: 'Finnish',
    es: 'finés',
    et: 'soome',
    fi: 'suomi',
    fr: 'finnois',
    it: 'finlandese',
    ja: 'フィンランド語',
    lt: 'suomių',
    lv: 'somu',
    nl: 'Fins',
    no: 'finsk',
    pl: 'fiński',
    pt: 'finlandês',
    ru: 'финский',
    sv: 'finska',
    tr: 'Fince',
    zh: '芬兰文',
    ko: '핀란드어',
  },
  lt: {
    cs: 'litevština',
    da: 'litauisk',
    de: 'Litauisch',
    en: 'Lithuanian',
    es: 'lituano',
    et: 'leedu',
    fi: 'liettua',
    fr: 'lituanien',
    it: 'lituano',
    ja: 'リトアニア語',
    lt: 'lietuvių',
    lv: 'lietuviešu',
    nl: 'Litouws',
    no: 'litauisk',
    pl: 'litewski',
    pt: 'lituano',
    ru: 'литовский',
    sv: 'litauiska',
    tr: 'Litvanyaca',
    zh: '立陶宛文',
    ko: '리투아니아어',
  },
  lv: {
    cs: 'lotyština',
    da: 'lettisk',
    de: 'Lettisch',
    en: 'Latvian',
    es: 'letón',
    et: 'läti',
    fi: 'latvia',
    fr: 'letton',
    it: 'lettone',
    ja: 'ラトビア語',
    lt: 'latvių',
    lv: 'latviešu',
    nl: 'Lets',
    no: 'latvisk',
    pl: 'łotewski',
    pt: 'letão',
    ru: 'латышский',
    sv: 'lettiska',
    tr: 'Letonca',
    zh: '拉脱维亚文',
    ko: '라트비아어',
  },
  nl: {
    cs: 'nizozemština',
    da: 'hollandsk',
    de: 'Niederländisch',
    en: 'Dutch',
    es: 'neerlandés',
    et: 'hollandi',
    fi: 'hollanti',
    fr: 'néerlandais',
    it: 'olandese',
    ja: 'オランダ語',
    lt: 'olandų',
    lv: 'holandiešu',
    nl: 'Nederlands',
    no: 'nederlandsk',
    pl: 'niderlandzki',
    pt: 'holandês',
    ru: 'нидерландский',
    sv: 'holländska',
    tr: 'Felemenkçe',
    zh: '荷兰文',
    ko: '네덜란드어',
  },
  no: {
    cs: 'norština',
    da: 'norsk',
    de: 'Norwegisch',
    en: 'Norwegian',
    es: 'noruego',
    et: 'norra',
    fi: 'norja',
    fr: 'norvégien',
    it: 'norvegese',
    ja: 'ノルウェー語',
    lt: 'norvegų',
    lv: 'norvēģu',
    nl: 'Noors',
    no: 'norsk bokmål',
    pl: 'norweski',
    pt: 'norueguês',
    ru: 'норвежский',
    sv: 'norska',
    tr: 'Norveççe',
    zh: '挪威文',
    ko: '노르웨이어',
  },
  pl: {
    cs: 'polština',
    da: 'polsk',
    de: 'Polnisch',
    en: 'Polish',
    es: 'polaco',
    et: 'poola',
    fi: 'puola',
    fr: 'polonais',
    it: 'polacco',
    ja: 'ポーランド語',
    lt: 'lenkų',
    lv: 'poļu',
    nl: 'Pools',
    no: 'polsk',
    pl: 'polski',
    pt: 'polonês',
    ru: 'польский',
    sv: 'polska',
    tr: 'Lehçe',
    zh: '波兰文',
    ko: '폴란드어',
  },
  sr: {
    en: 'Serbian',
    sr: 'Srpski',
  },
  sv: {
    cs: 'švédština',
    da: 'svensk',
    de: 'Schwedisch',
    en: 'Swedish',
    es: 'sueco',
    et: 'rootsi',
    fi: 'ruotsi',
    fr: 'suédois',
    it: 'svedese',
    ja: 'スウェーデン語',
    lt: 'švedų',
    lv: 'zviedru',
    nl: 'Zweeds',
    no: 'svensk',
    pl: 'szwedzki',
    pt: 'sueco',
    ru: 'шведский',
    sv: 'svenska',
    tr: 'İsveççe',
    zh: '瑞典文',
    ko: '스웨덴어',
  },
  tr: {
    cs: 'turečtina',
    da: 'tyrkisk',
    de: 'Türkisch',
    en: 'Turkish',
    es: 'turco',
    et: 'türgi',
    fi: 'turkki',
    fr: 'turc',
    it: 'turco',
    ja: 'トルコ語',
    lt: 'turkų',
    lv: 'turku',
    nl: 'Turks',
    no: 'tyrkisk',
    pl: 'turecki',
    pt: 'turco',
    ru: 'турецкий',
    sv: 'turkiska',
    tr: 'Türkçe',
    zh: '土耳其文',
    ko: '터키어',
  },
  en_GB: {
    da: 'britisk engelsk',
    de: 'Britisches Englisch',
    en: 'British English',
    es: 'inglés británico',
    fi: 'britannianenglanti',
    fr: 'anglais britannique',
    it: 'inglese britannico',
    ja: 'イギリス英語',
    lt: 'anglų (britų)',
    lv: 'Lielbritānijas angļu',
    nl: 'Brits Engels',
    no: 'britisk engelsk',
    pl: 'angielski (Wlk. Brytania)',
    pt: 'inglês britânico',
    ru: 'британский английский',
    sv: 'brittisk engelska',
    tr: 'İngilizce (İngiltere)',
    zh: '英语（英国）',
    ko: '영어 (영국식)',
  },
  en_US: {
    cs: 'angličtina (USA)',
    da: 'amerikansk engelsk',
    de: 'Amerikanisches Englisch',
    en: 'U.S. English',
    es: 'inglés estadounidense',
    et: 'inglise (USA)',
    fi: 'amerikanenglanti',
    fr: 'anglais américain',
    it: 'inglese americano',
    ja: '英語 (アメリカ)',
    lt: 'anglų (amerikiečių)',
    lv: 'angļu (ASV)',
    nl: 'Amerikaans Engels',
    no: 'amerikansk engelsk',
    pl: 'angielski (USA)',
    pt: 'inglês americano',
    ru: 'американский английский',
    sv: 'amerikansk engelska',
    tr: 'İngilizce (ABD)',
    zh: '英语（美国）',
    ko: '영어 (미국식)',
  },
}
