/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PidxClickTrackingDirective } from './pidx-click-tracking.directive'
import { PidxFormValidationTrackingDirective } from './pidx-form-validation-tracking.directive'
import { PidxAutoFocusDirective } from './pidx-auto-focus.directive'

const DIRECTIVES = [PidxClickTrackingDirective, PidxFormValidationTrackingDirective, PidxAutoFocusDirective]

@NgModule({
  declarations: [...DIRECTIVES],
  imports: [CommonModule],
  exports: [...DIRECTIVES],
})
export class DirectivesModule {}
