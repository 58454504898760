/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component } from '@angular/core'

@Component({
  selector: 'myprofile-signup-ui-layout-success',
  templateUrl: './layout-success.component.html',
  styleUrls: ['./layout-success.component.scss'],
})
export class LayoutSuccessComponent {
}
