/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { AfterViewInit, Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild } from '@angular/core'
import {Localization, localizations, WidgetInstance} from 'friendly-challenge'
import { map } from 'rxjs/operators'
import { Observable } from 'rxjs'
import { TranslateService } from '@ngx-translate/core'
import {ConfigService} from "../../services/config/config.service";

@Component({
  selector: 'myprofile-signup-ui-friendly-captcha',
  templateUrl: './friendly-captcha.component.html',
})
export class FriendlyCaptchaComponent implements OnInit, AfterViewInit {
  static readonly friendlyCaptchaFallbackHTML = `This site is protected by Friendly Captcha and the Friendly Captcha
    <a href='https://friendlycaptcha.com/legal/privacy-end-users' class='underline hover:no-underline' target='_blank'
       rel='noopener noreferrer nofollow'>Privacy Policy</a> and
    <a href='https://friendlycaptcha.com/legal/terms' class='underline hover:no-underline' target='_blank'
       rel='noopener noreferrer nofollow'>Terms of Service</a> apply.`

  @ViewChild('friendlyCaptchaWidget') container!: ElementRef<HTMLElement>
  widget: WidgetInstance | undefined

  @Output() captcha = new EventEmitter<string>()

  @Input() startMode: 'auto' | 'focus' | 'none' | undefined
  @Input() siteKey: string | undefined

  public friendlyCaptchaTranslation$: Observable<string>

  constructor(private _translationService: TranslateService, private _configService: ConfigService) {}

  ngOnInit() {
    this.friendlyCaptchaTranslation$ = this._translationService
      .get('signup.register.prospect.friendlycaptcha.label')
      .pipe(map((translation) => (translation.includes('[missing]') ? FriendlyCaptchaComponent.friendlyCaptchaFallbackHTML : translation)))
  }

  ngAfterViewInit() {
    const element = this.container.nativeElement
    let locale = this._configService.getUrlLocale().substring(0, 2)
    if(locale==='ko') locale = 'kr'
    this.widget = new WidgetInstance(element, {
      startMode: this.startMode ?? 'focus',
      sitekey: this.siteKey,
      language: localizations[locale] || localizations['en'],
      doneCallback: (solution) => this.captcha.next(solution),
    })
  }
}
