/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http'
import { Injectable } from '@angular/core'
import { concatMap, first, from, iif, Observable, of } from 'rxjs'
import { catchError, map, switchMap } from 'rxjs/operators'
import {
  ApiRouteDefinition,
  AuthService,
  HttpInterceptorConfig,
  HttpInterceptorRouteConfig,
  isHttpInterceptorRouteConfig,
} from '@auth0/auth0-angular'
import { ConfigService } from '../services/config/config.service'

@Injectable()
export class CustomAuthHttpInterceptor implements HttpInterceptor {
  constructor(private authService: AuthService, private configService: ConfigService) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const config = this.configService.getHttpInterceptorConfig()
    if (!config?.allowedList) {
      return next.handle(req)
    }

    return this.findMatchingRoute(req, config).pipe(
      concatMap((route) =>
        iif(
          () => route !== null,
          of(route).pipe(
            map((route: HttpInterceptorRouteConfig) => route?.tokenOptions),
            concatMap((options) => {
              return this.authService.getAccessTokenSilently(options).pipe(
                catchError(() => {
                  this.redirectToLogin()
                  // Will technically never happen since we redirect the customer to the login page
                  return of(null)
                }),
              )
            }),
            switchMap((token: string) => {
              const clone = token
                ? req.clone({
                    setHeaders: { Authorization: `Bearer ${token}` },
                  })
                : req

              return next.handle(clone)
            }),
          ),
          next.handle(req),
        ),
      ),
    )
  }

  private stripQueryFrom(uri: string): string {
    if (uri.indexOf('?') > -1) {
      uri = uri.substr(0, uri.indexOf('?'))
    }

    if (uri.indexOf('#') > -1) {
      uri = uri.substr(0, uri.indexOf('#'))
    }

    return uri
  }

  private canAttachToken(route: ApiRouteDefinition, request: HttpRequest<any>): boolean {
    const testPrimitive = (value: string | undefined): boolean => {
      if (!value) {
        return false
      }

      const requestPath = this.stripQueryFrom(request.url)

      if (value === requestPath) {
        return true
      }

      // If the URL ends with an asterisk, match using startsWith.
      return value.indexOf('*') === value.length - 1 && request.url.startsWith(value.substr(0, value.length - 1))
    }

    if (isHttpInterceptorRouteConfig(route)) {
      if (route.httpMethod && route.httpMethod !== request.method) {
        return false
      }

      /* istanbul ignore if */
      if (!route.uri && !route.uriMatcher) {
        console.warn('Either a uri or uriMatcher is required when configuring the HTTP interceptor.')
      }

      return route.uriMatcher ? route.uriMatcher(request.url) : testPrimitive(route.uri)
    }

    return testPrimitive(route)
  }

  private findMatchingRoute(request: HttpRequest<any>, config: HttpInterceptorConfig): Observable<ApiRouteDefinition | null> {
    return from(config.allowedList).pipe(first((route) => this.canAttachToken(route, request), null))
  }

  private redirectToLogin() {
    const country = this.configService.getUrlCountry()
    const language = this.configService.getUrlLocale().split('_').shift()
    this.authService.loginWithRedirect({
      appState: { target: window.location.pathname },
      authorizationParams: {
        'ext-country': country,
        'ext-language': language,
        ui_locales: language,
      },
    })
  }
}
