/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core'
import { Action, State, StateContext, Store } from '@ngxs/store'
import { ConfirmationCodeStatus } from '../../enums/confirmation-code-status'
import { ERROR_KEY } from '../../pages/error-page/error-page-data'
import { SignupService } from '../../services/signup/signup.service'
import { ConfirmationStep } from '../confirmation-step.enum'
import { ConfirmationStateModel } from './confirmation-state.model'
import { Confirmation } from './confirmation.actions'
import { lastValueFrom } from 'rxjs'
import { TransactionType } from '../../enums/transaction-type'
import { RegistrationType } from '../../enums/registration-type'
import { ToastManager } from '@porsche-design-system/components-angular'
import { TranslateService } from '@ngx-translate/core'
import ActivateVehicle = Confirmation.ActivateVehicle
import ActivationSuccessful = Confirmation.ActivationSuccessful
import Init = Confirmation.Init
import RequestOTP = Confirmation.RequestOTP
import SetPassword = Confirmation.SetPassword
import SetSPIN = Confirmation.SetSPIN
import ValidateOTP = Confirmation.ValidateOTP
import ProceedOwner = Confirmation.ProceedConfirmation;
import ProceedConfirmation = Confirmation.ProceedConfirmation;

@State<ConfirmationStateModel>({
  name: 'confirmation',
  defaults: {
    codeStatus: ConfirmationCodeStatus.CODE_STATUS_DEFAULT,
    step: ConfirmationStep.REQUEST_SMS_CODE,
    type: null,
    transactionType: null,
    confirmationStatus: false,
    confirmationLoading: false,
    otpRequested: false,
    otpRequestBlocked: false,
    spinRequested: false,
    success: false,
    connectSuccess: false,
    nonConnectSuccess: false,
    error_key: null,
    confirmationCode: null,
    baseModel: null
  },
})
@Injectable({
  providedIn: 'root',
})
export class ConfirmationState {
  constructor(
    private readonly signupService: SignupService,
    private readonly store: Store,
    private readonly toastManager: ToastManager,
    private readonly translateService: TranslateService,
  ) {}

  @Action(ActivateVehicle)
  public async activateVehicle(ctx: StateContext<ConfirmationStateModel>, action: ValidateOTP) {
    ctx.patchState({ confirmationLoading: true })
    const publicKeys = await lastValueFrom(this.signupService.getPublicKeyForPasswordEncryption())

    const payload = {
      tid: this.signupService.transactionId,
      otps: [action.otp, this.signupService.oneTimeToken],
      keyId: publicKeys.keys[0].kid,
    }
    try {
      await lastValueFrom(this.signupService.confirmAccount(this.transactionType, payload))
      ctx.patchState({ confirmationLoading: false })

      if (this.signupService.transactionData.needsSpin) {
        ctx.patchState({
          step: ConfirmationStep.SET_SPIN,
          confirmationStatus: true,
        })
      } else {
        this.store.dispatch(new ActivationSuccessful())
      }
    } catch (e) {
      ctx.patchState({ confirmationStatus: false })
      if (e.error.errorCode == ERROR_KEY.BUSINESS_CONSTRAINT_PROFILE_TMP_DATA_NOT_FOUND) {
        ctx.patchState({error_key: ERROR_KEY.BUSINESS_CONSTRAINT_PROFILE_TMP_DATA_NOT_FOUND})
      } else {
        ctx.patchState({ error_key: ERROR_KEY.GENERIC })
      }
    }
  }

  @Action(Init)
  public initState(ctx: StateContext<ConfirmationStateModel>, action: Init) {
    const registrationType = this.signupService.registrationType
    const isNewCustomerWithVehicle = ['CONNECT_OWNER', 'NON_CONNECT_OWNER', 'SECONDARY'].includes(this.signupService.transactionType)
    const isConfirmIdentity = registrationType === RegistrationType.MOBILE && !this.signupService.simplifiedAddVehicle
    ctx.patchState({
      type: action.type,
      baseModel: action.baseModel,
      transactionType: action.transactionType,
      step: isNewCustomerWithVehicle ? ConfirmationStep.CONFIRMATION_INTRODUCTION : isConfirmIdentity ? ConfirmationStep.CONFIRM_IDENTITY : ConfirmationStep.REQUEST_SMS_CODE,
    })
  }

  @Action(ProceedConfirmation)
  public proceedConfirmation(ctx: StateContext<ConfirmationStateModel>) {
    const registrationType = this.signupService.registrationType
    const isConfirmIdentity = registrationType === RegistrationType.MOBILE && !this.signupService.simplifiedAddVehicle
    ctx.patchState({
      step: isConfirmIdentity ? ConfirmationStep.CONFIRM_IDENTITY : ConfirmationStep.REQUEST_SMS_CODE,
    })
  }

  /**
   * Toggles otpRequested state to prevent multiple fast-clicking events and releases it again afterwards.
   * Timeout depends on action trigger situation: If the action was triggered as non-resend request, there
   * should not be any subsequent presses until the next form page is rendered. If it is a resend, this may
   * be allowed but with a timeout of 3 seconds in between to prevent rage-click-based requests.
   */
  @Action(RequestOTP)
  public async requestOTP(ctx: StateContext<ConfirmationStateModel>, action: RequestOTP) {
    ctx.patchState({ otpRequested: true })
    ctx.patchState({ otpRequestBlocked: false })
    try {
      await lastValueFrom(this.signupService.requestOTP())
      const toast = this.translateService.instant('signup.activateAccount.modal.confirmationCodeSent.body')
      this.toastManager.addMessage({ state: 'info', text: toast })
      if (!action.resend) ctx.patchState({ step: ConfirmationStep.CONFIRM_IDENTITY })
      setTimeout(
        () => {
          ctx.patchState({ otpRequested: false })
        },
        action.resend ? 3000 : 0,
      )
    } catch (e) {
      if (
        (e.status === 409 && e.error?.errorCode === 'BUSINESS_EXCEPTION_TRANSACTION_AUTHENTICATION_OPTION_TEMPORARILY_BLOCKED') ||
        (e.status === 429 && e.error?.errorCode === 'TOO_MANY_REQUESTS_OTP')
      ) {
        ctx.patchState({ otpRequestBlocked: true })
        ctx.patchState({ otpRequested: false })
      }
    }
  }

  @Action(SetPassword)
  public async setPassword(ctx: StateContext<ConfirmationStateModel>, action: SetPassword) {
    ctx.patchState({ confirmationLoading: true })
    try {
      const publicKeys = await lastValueFrom(this.signupService.getPublicKeyForPasswordEncryption())
      const encryptedPassword = await this.signupService.encryptPassword(publicKeys.keys[0], action.password)

      const payload = {
        tid: this.signupService.transactionId,
        otps: [
          ...(action.otp ? [action.otp] : []), this.signupService.oneTimeToken
        ],
        encryptedPassword,
        keyId: publicKeys.keys[0].kid,
        ...action.preparedPayload,
      }

      //We need to remove the *unencrypted* password
      delete payload.password

      //We need to remove legalText in case it's present
      delete payload.legalText

      await lastValueFrom(this.signupService.confirmAccount(this.transactionType, payload))
      ctx.patchState({ confirmationLoading: false })

      const needsSpin = this.signupService.transactionData.needsSpin

      // If the vehicle supports SPIN, patch step to SET_SPIN
      if (needsSpin) {
        ctx.patchState({ step: ConfirmationStep.SET_SPIN })
        return
      }

      // if we are done here, set activation to successful
      this.store.dispatch(new ActivationSuccessful())
    } catch (e) {
      ctx.patchState({
        confirmationLoading: false,
        confirmationStatus: false,
      })
      if (e.error.errorCode == ERROR_KEY.BUSINESS_CONSTRAINT_PROFILE_TMP_DATA_NOT_FOUND) {
        ctx.patchState({ error_key: ERROR_KEY.BUSINESS_CONSTRAINT_PROFILE_TMP_DATA_NOT_FOUND })
      } else {
        ctx.patchState({ error_key: ERROR_KEY.GENERIC })
      }
    }
  }

  @Action(SetSPIN)
  public async setSPIN(ctx: StateContext<ConfirmationStateModel>, action: SetSPIN) {
    ctx.patchState({ spinRequested: true })
    const toast = this.translateService.instant('signup.createSpinStep.errorMessages.banner.body')
    try {
      const publicKey = await lastValueFrom(this.signupService.getPublicKeyForSpinEncryption())
      const encryptedSpin = await this.signupService.encryptSPIN(publicKey, action.spin)

      const response = await lastValueFrom(
        this.signupService.setSPIN(this.signupService.transactionId, this.signupService.oneTimeToken, encryptedSpin),
      )
      if (response.failure && response.failure > 0) {
        this.toastManager.addMessage({ state: 'info', text: toast })
      }
    } catch (e) {
      this.toastManager.addMessage({ state: 'info', text: toast })
    } finally {
      // Even if setting the SPIN doesn't work, we just redirect to the next step without interrupting the confirmation flow
      ctx.patchState({ spinRequested: false })
      this.store.dispatch(new ActivationSuccessful())
    }
  }

  @Action(ActivationSuccessful)
  public setSuccess(ctx: StateContext<ConfirmationStateModel>) {
    const isConnect =
      this.signupService.serviceActivationAvailable &&
      ![TransactionType.SECONDARY, TransactionType.SECONDARY_VEHICLE].includes(this.signupService.transactionType)

    if (isConnect) {
      ctx.patchState({ connectSuccess: true, success: true })
      return
    }

    ctx.patchState({ nonConnectSuccess: true, success: true })
  }

  @Action(ValidateOTP)
  public async validateOTP(ctx: StateContext<ConfirmationStateModel>, action: ValidateOTP) {
    ctx.patchState({ codeStatus: ConfirmationCodeStatus.CODE_STATUS_VALIDATION_PENDING })
    try {
      const registrationType = this.signupService.registrationType
      const isConfirmIdentity = registrationType === RegistrationType.MOBILE && !this.signupService.simplifiedAddVehicle
      await lastValueFrom(this.signupService.verifyOTP(action.otp, isConfirmIdentity ? 'DOB' : 'SMS'))
      ctx.patchState({
        codeStatus: ConfirmationCodeStatus.CODE_STATUS_VALID,
        confirmationCode: action.otp,
      })

      if (registrationType === RegistrationType.MOBILE) {
        const toast = this.translateService.instant('signup.activateAccount.confirmationCode.infoMessages.success.banner.title')
        this.toastManager.addMessage({ state: 'success', text: toast })
      }

      if (ctx.getState().type === 'VEHICLE' || ctx.getState().type === 'SECONDARY_VEHICLE') {
        this.store.dispatch(new ActivateVehicle(action.otp))
      } else {
        // from a ux perspective it makes sense to wait for the code input to be verified before going to the next step
        await new Promise((resolve) => setTimeout(resolve, 500))
        ctx.patchState({ step: ConfirmationStep.SET_PASSWORD })
      }
    } catch (e) {
      if (e.status === 409 && e.error?.errorCode === 'BUSINESS_EXCEPTION_TRANSACTION_AUTHENTICATION_OPTION_TEMPORARILY_BLOCKED') {
        ctx.patchState({
          codeStatus: ConfirmationCodeStatus.CODE_STATUS_TEMPORARILY_BLOCKED,
        })
      } else {
        ctx.patchState({
          codeStatus: ConfirmationCodeStatus.CODE_STATUS_INVALID,
        })
      }
    }
  }

  private get transactionType(): TransactionType {
    return this.signupService.transactionType
  }
}
