/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Inject, Injectable } from '@angular/core'
import { HttpErrorResponse } from '@angular/common/http'
import { WINDOW } from "../utils";

export enum PageAction {
  APP_LOADED = 'Signup-AppLoaded',
}

@Injectable({
  providedIn: 'root',
})
export class NewRelicService {
  constructor(@Inject(WINDOW) private readonly window: Window) {}

  addPageAction(country: string) {
    if (this.window['newrelic']) {
      this.window['newrelic'].addPageAction(PageAction.APP_LOADED, {
        timestamp: new Date().toISOString(),
        appCountry: country,
      })
    }
  }

  noticeError(error: Error | HttpErrorResponse, traceId?: string) {
    if (this.window['newrelic']) {
      if (error instanceof HttpErrorResponse) {
        this.window['newrelic'].noticeError(error, {
          'x-trace-id': traceId,
        })
      } else {
        this.window['newrelic'].noticeError(error)
      }
    }
  }
}
