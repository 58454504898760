<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<p-text size="small" class="mr-static_xs inline-block" *ngIf="!isEnabled">{{ timerLabel }}</p-text>
<p-link-pure
  *ngIf="isEnabled"
  [ngClass]="{
    'underline-link': true,
    'inactive-pure-link': inactiveTimerButton
  }"
  class="mr-static_xs inline-block"
  (click)="clickHandler()"
  [icon]="'none'"
>
  <a>{{ requestCodeTimerLabel }}</a>
</p-link-pure>
