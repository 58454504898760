/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from "@angular/core";
import { BehaviorSubject, combineLatest, from, mergeMap, Observable, of } from "rxjs";
import { catchError, filter, map, tap } from "rxjs/operators";
import { HttpClient } from "@angular/common/http";
import { ConfigService } from "../config/config.service";
import { FastAverageColor } from 'fast-average-color';

type DeviceType = 'web' | 'mobile'
type PictureKind = 'background0' | 'background1'

type PictureProps = {
  isFallbackImage: boolean
  base64Encoded: string
  averageColor: string
  isLight: boolean
}

type PictureMap = Record<DeviceType, Record<PictureKind, PictureProps>>

type PictureSrcData = {
  src: string
  media: string
  type: string
}

const defaultPictures = {
  web: {
    background0: 'assets/images/fallback-web-front-picture.webp',
    background1: 'assets/images/fallback-web-back-picture.webp',
  },
  mobile: {
    background0: 'assets/images/fallback-mobile-front-picture.webp',
  }
}

@Injectable({
  providedIn: 'root'
})
export class PictureService {
  private pictureMap$$: BehaviorSubject<PictureMap> = new BehaviorSubject<PictureMap>(null)

  constructor(
    private _configService: ConfigService,
    private _httpClient: HttpClient
  ) {
  }

  public fetchPicturesFor(tid: string): void {
    combineLatest([
      this.initPicturePropsFor(tid, 'web', 'background0'),
      this.initPicturePropsFor(tid, 'web', 'background1'),
      this.initPicturePropsFor(tid, 'mobile', 'background0'),
    ]).pipe(
      tap(([webBackground0, webBackground1, mobileBackground0]) => {
        if (webBackground0 && webBackground1 && mobileBackground0) {
          this.pictureMap$$.next({
            web: {
              background0: webBackground0,
              background1: {
                // We use the average color from only the first picture, because the value would change slightly
                ...webBackground0,
                base64Encoded: webBackground1.base64Encoded
              },
            },
            mobile: {
              background0: mobileBackground0,
              /**
               * because we show just the front of the car on mobile,
               * we save ourselves some trouble and show the same
               * picture so we don't have to come up with special logic
               */
              background1: mobileBackground0
            }
          })
        }
      })
    ).subscribe()
  }

  public getFrontImages(): Observable<PictureSrcData[]> {
    return this.pictureMap$$.pipe(
      filter((value) => !!value),
      map((pictureMap: PictureMap) => {
        const webPicture = pictureMap.web.background0
        const mobilePicture = pictureMap.mobile.background0

        const webImageData: PictureSrcData = {
          src: webPicture.base64Encoded,
          media: '(min-width: 1300px)',
          type: 'image/webp'
        }

        const mobileImageData: PictureSrcData = {
          src: mobilePicture.base64Encoded,
          media: '(max-width: 1300px)',
          type: 'image/webp'
        }

        return [webImageData, mobileImageData]
      })
    )
  }

  public getBackImages(): Observable<PictureSrcData[]> {
    return this.pictureMap$$.pipe(
      filter((value) => !!value),
      map((pictureMap: PictureMap) => {
        const webPicture = pictureMap.web.background1
        const mobilePicture = pictureMap.mobile.background1

        const webImageData: PictureSrcData = {
          src: webPicture.base64Encoded,
          media: '(min-width: 1300px)',
          type: 'image/webp'
        }

        const mobileImageData: PictureSrcData = {
          src: mobilePicture.base64Encoded,
          media: '(max-width: 1300px)',
          type: 'image/webp'
        }

        return [webImageData, mobileImageData]
      })
    )
  }

  public getPictureMetaData(): Observable<Omit<PictureProps, 'base64Encoded'>> {
    return this.pictureMap$$.pipe(
      filter((value) => !!value),
      map((pictureMap: PictureMap) => {
        return {
          isFallbackImage: pictureMap.web.background0.isFallbackImage,
          isLight: pictureMap.web.background0.isLight,
          averageColor: pictureMap.web.background0.averageColor,
        }
      })
    )
  }

  private initPicturePropsFor(tid: string, deviceType: DeviceType, pictureKind: PictureKind): Observable<PictureProps | null> {
    if (!this._configService.isPictureServiceEnabled()) {
      return this.fetchPictureAndMapToPictureProps(defaultPictures[deviceType][pictureKind])
    }

    const url = this._configService.getExternalUrl('pictureService')
    const pictureUrl = `${url}/${tid}/${deviceType}/${pictureKind}.webp`

    return this.fetchPictureAndMapToPictureProps(pictureUrl).pipe(
      catchError(() =>
        this.fetchPictureAndMapToPictureProps(defaultPictures[deviceType][pictureKind], true)
      )
    )
  }

  private fetchPictureAndMapToPictureProps(url: string, isFallbackImage = false): Observable<PictureProps> {
    return this._httpClient.get(url, {
      responseType: 'blob'
    }).pipe(
      mergeMap(this.blobToBase64),
      mergeMap((base64Encoded) =>
        this.getAverageColorProps(base64Encoded).pipe(
          map((averageColorProps) => ({
              isFallbackImage,
              base64Encoded,
              averageColor: averageColorProps.color,
              isLight: averageColorProps.isLight
            }
          ))
        )),
    )
  }

  private blobToBase64(blob: Blob): Observable<string> {
    return new Observable<string>(observer => {
      const reader = new FileReader()

      reader.onloadend = () => {
        observer.next(reader.result as string)
        observer.complete()
      };

      reader.onerror = error => {
        observer.error(error)
      };

      reader.readAsDataURL(blob)

      // Cleanup function
      return () => {
        reader.abort()
      }
    })
  }

  private getAverageColorProps(urlEncodedPicture: string) {
    const fac = new FastAverageColor()
    return from(fac.getColorAsync(urlEncodedPicture)).pipe(
      map(color => ({
        color: `${color.hex}`,
        isLight: color.isLight
      })),
      catchError(error => {
        console.log(error)
        return of(undefined)
      })
    )
  }
}
