/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { PidxGoogleTagManagerIntegrationService } from '../services/google-tag-manager/pidx-google-tag-manager-integration.service'
import { Directive, ElementRef, HostListener, Input } from '@angular/core'
import { ExtendedClickData } from '../services/google-tag-manager/pidx-google-tag-manager-integration.interfaces'

@Directive({
  // eslint-disable-next-line @angular-eslint/directive-selector
  selector: '[myprofileSignupUiTrack]',
})
export class PidxClickTrackingDirective {
  @Input('myprofileSignupUiTrack') trackingData: ExtendedClickData

  constructor(private _elementRef: ElementRef, private _googleTagManagerIntegrationService: PidxGoogleTagManagerIntegrationService) {}

  @HostListener('click') onClick() {
    setTimeout(() => {
      const containingForm = this._elementRef.nativeElement.closest('form')

      // Check if click occurred inside a form, if not trigger click
      if (!containingForm) {
        this._googleTagManagerIntegrationService.triggerClickTracking(this.trackingData)
      } else {
        // Check if click occurred inside a form which has a validation directive, if not trigger click
        const formValidationTrackingId = containingForm.getAttribute('myprofileSignupUiValidationTrack')

        if (!formValidationTrackingId || !this._googleTagManagerIntegrationService.hasValidationErrors(formValidationTrackingId)) {
          this._googleTagManagerIntegrationService.triggerClickTracking(this.trackingData)
        } else {
          this._googleTagManagerIntegrationService.trackInvalidInputEntry(formValidationTrackingId, this.trackingData)
        }
      }
    }, 250)
  }
}
