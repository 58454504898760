<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->
<div class="bg-gradient-color h-full" [style.--bg-gradient-from]="gradientColor">
  <myprofile-signup-ui-navigation [navMode]="'transparent'"></myprofile-signup-ui-navigation>
  <div class="basic-content-grid h-full">
    <main class="basic-content h-full">
      <!-- *ngIf="true" hack is required here for router-outlet to work correctly. Otherwise,
           it won't register the changes on the layout and won't update the view. -->
      <router-outlet *ngIf="true"></router-outlet>
    </main>
  </div>
</div>
