/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { BaseFormControlComponent } from './form-controls/base-form-control.component'
import { DynamicFormGroupModel } from '../model/form-group/dynamic-form-group.model'
import { Component, EventEmitter, Input, Output, QueryList } from '@angular/core'
import { DynamicFormControlContainerComponent } from './dynamic-form-control-container.component'
import { UntypedFormGroup } from '@angular/forms'
import { DynamicFormLayout } from '../service/dynamic-form-layout.service'
import { DynamicFormControlLayout } from '../model/misc/dynamic-form-control-layout.model'

@Component({
  selector: 'dynamic-form-group',
  templateUrl: './dynamic-form-group.component.html',
})
export class DynamicFormGroupComponent extends BaseFormControlComponent {
  @Input() formLayout: DynamicFormLayout
  @Input() group: UntypedFormGroup
  @Input() layout: DynamicFormControlLayout
  @Input() model: DynamicFormGroupModel

  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() blur: EventEmitter<any> = new EventEmitter()
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() change: EventEmitter<any> = new EventEmitter()
  // eslint-disable-next-line @angular-eslint/no-output-native
  @Output() focus: EventEmitter<any> = new EventEmitter()
  @Output() metaDataSelectedEvent: EventEmitter<any> = new EventEmitter()

  components: QueryList<DynamicFormControlContainerComponent>

  markForCheck() {
    if (this.components instanceof QueryList) {
      this.components.forEach((component) => component.markForCheck())
    }
  }

  public metaDataSelected($event: any) {
    this.metaDataSelectedEvent.emit($event)
  }
}
