/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule } from '@angular/core'
import { BrowserModule } from '@angular/platform-browser'
import { NgxsModule } from '@ngxs/store'
import { RouterModule } from '@angular/router'
import { HTTP_INTERCEPTORS, HttpClient, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http'
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'
import { AppComponent } from './app.component'
import { environment } from '../environments/environment'
import { ConfigService } from './services/config/config.service'
import { AppRoutingModule } from './app-routing.module'
import { API_KEY } from './constants'
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular'
import { XTRACE_ID_PREFIX, XTraceInterceptor } from './interceptors/x-trace.interceptor'
import { ApiKeyInterceptor } from './interceptors/api-key.interceptor'
import { LocaleRetrieveUrlParamsSignupService } from './services/config/locale-retrieve-url-params-signup.service'
import { LayoutModule } from './layout/layout.module'
import { ValidatorsModule } from './validators/validators.module'
import { HttpErrorInterceptorProvider } from './interceptors/error.interceptor'
import { ReactiveFormsModule } from '@angular/forms'
import { ComponentsModule } from './components/components.module'
import { CustomLocaleLoaderService } from './services/custom-locale-loader.service'
import { DecoratorService } from './services/loading/decorator.service'
import { DirectivesModule } from './directive/directives.module'
import { worker } from '../mocks/browser'
import { NgxsReduxDevtoolsPluginModule } from '@ngxs/devtools-plugin'
import { LegalConditionState } from './state/legal-condition/legal-condition.state'
import { CustomAuthHttpInterceptor } from './interceptors/custom-auth-http.interceptor'
import { AuthModule } from '@auth0/auth0-angular'
import { CookieConsentImplementationService } from './services/cookie-consent/cookie-consent.service'
import { CookieConsentConfig } from './services/cookie-consent/cookie-consent-config'
import { TranslateLoader, TranslateModule } from '@ngx-translate/core'
import { ConfigLoaderService } from './services/config/config-loader.service'
import { HttpLoaderFactory } from './services/config/translate-loader.service'
import { NewRelicErrorHandler } from "./services/error/newrelic-error-handler"

if (environment.mockServiceWorker.enabled) {
  worker.start({
    // Ignore and do not log unhandled requests in browser console
    onUnhandledRequest: 'bypass',
  })
}

//<editor-fold desc="COMPONENTS">
const COMPONENTS = [AppComponent]
//</editor-fold>

//<editor-fold desc="MODULES">
const ANGULAR_MODULES = [BrowserModule, BrowserAnimationsModule, RouterModule, ReactiveFormsModule]

const THIRD_PARTY_MODULES = [AuthModule.forRoot(environment.oauth)]

const PORSCHE_MODULES = [PorscheDesignSystemModule]

const OTHER_MODULES = [
  AppRoutingModule,
  LayoutModule,
  ValidatorsModule,
  ComponentsModule,
  DirectivesModule,

  NgxsModule.forRoot([LegalConditionState], {
    developmentMode: environment.ngxs.developmentMode,
  }),
  NgxsReduxDevtoolsPluginModule.forRoot({
    disabled: environment.ngxs.devToolsDisabled,
  }),
  TranslateModule.forRoot({
    loader: {
      provide: TranslateLoader,
      useFactory: HttpLoaderFactory,
      deps: [HttpClient, ConfigService, ConfigLoaderService],
    },
  }),
]
//</editor-fold>

//<editor-fold desc="PROVIDERS">
const INTERCEPTORS = [
  {
    provide: HTTP_INTERCEPTORS,
    useClass: ApiKeyInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: XTraceInterceptor,
    multi: true,
  },
  {
    provide: HTTP_INTERCEPTORS,
    useClass: CustomAuthHttpInterceptor,
    multi: true,
  },
  HttpErrorInterceptorProvider,
  LocaleRetrieveUrlParamsSignupService,
]

const OTHER_PROVIDERS = [
  { provide: API_KEY, useValue: environment.oauth.clientId },
  { provide: XTRACE_ID_PREFIX, useValue: 'SIGNUP' },
  { provide: ErrorHandler, useValue: NewRelicErrorHandler },
  ConfigService,
  ConfigLoaderService,
  CustomLocaleLoaderService,
  CookieConsentImplementationService,
  CookieConsentConfig,
]

//</editor-fold>

@NgModule({
  declarations: [...COMPONENTS],
  imports: [...ANGULAR_MODULES, ...THIRD_PARTY_MODULES, ...PORSCHE_MODULES, ...OTHER_MODULES],
  providers: [...OTHER_PROVIDERS, ...INTERCEPTORS, provideHttpClient(withInterceptorsFromDi())],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  bootstrap: [AppComponent],
})
export class AppModule {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  constructor(decoratorService: DecoratorService) {
    // Explicit injection of decoratorService in order to have it available in functions (e.g. decorators)
    // which work outside the angular dependency injection system
  }
}
