/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Component } from '@angular/core'
import { Observable } from 'rxjs'
import { OrganisationContactInformation } from '../../models/organisation-contact-information'
import { SignupService } from '../../services/signup/signup.service'
import { filter, map } from 'rxjs/operators'

@Component({
  selector: 'myprofile-signup-ui-contact-box',
  templateUrl: './contact-box.component.html',
  styleUrls: ['./contact-box.component.css'],
})
export class ContactBoxComponent {
  public readonly contactInformation$: Observable<OrganisationContactInformation | null>

  constructor(private _signupService: SignupService) {
    this.contactInformation$ = this._signupService.getContactOrganisation().pipe(
      filter((data) => !!data && data.length > 0),
      map((data) => data[0]),
    )
  }

  contactEmailLinkOut(contactEmail: string): string {
    return `mailto:${contactEmail}`
  }
}
