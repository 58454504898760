<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<ng-container *ngIf="friendlyCaptchaTranslation$ | async as friendlyCaptchaTranslation">
  <div #friendlyCaptchaWidget class="frc-captcha mt-static_l"></div>
  <p-text id="friendlyCaptcha-attribution" class="mt-static_m" [innerHTML]="friendlyCaptchaTranslation"></p-text>
</ng-container>
