<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<div *ngIf="contactInformation$ | async as contactInformation" class="text-gray-500">
  <p-text class="text-2xl" color="inherit">{{ 'signup.supportBox.headline' | translate }}</p-text>
  <p-text class="text-lg mt-static_s" color="inherit">{{ 'signup.supportBox.subline' | translate }}</p-text>

  <div>
    <p-text class="mt-static_s" color="inherit" *ngIf="contactInformation.telephoneNumber">
      <p-icon class="mr-static_s" name="phone" color="inherit"></p-icon> {{ contactInformation.telephoneNumber }}
    </p-text>
    <p-link-pure class="mt-static_s" [icon]="'none'" *ngIf="contactInformation.email">
      <a [href]="contactEmailLinkOut(contactInformation.email)">
        <p-icon class="mr-static_s" name="email" color="inherit"></p-icon>
        <span class="text-gray-500">{{ contactInformation.email }}</span>
      </a>
    </p-link-pure>
  </div>
</div>
