/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { PasswordStrengthMeterComponent } from './password-strength-meter.component'
import { TranslateModule } from '@ngx-translate/core'

@NgModule({
  imports: [CommonModule, TranslateModule],
  declarations: [PasswordStrengthMeterComponent],
  exports: [PasswordStrengthMeterComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class PasswordStrengthMeterModule {}
