<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->
<p-heading tag="h1" class="text-balance mt-static_l xs:mt-l">{{ 'signup.owner.confirmation.password.headline' | translate }}</p-heading>

<div class="w-full">
  <p-text size="{base: 'small', m: 'medium' }" class="mt-static_m s:mt-static_l text-balance">
    <span [innerText]="'signup.owner.confirmation.password.subline' | translate"></span>
  </p-text>
</div>

<div class="mt-m">
  <form [formGroup]="formGroup">
    <dynamic-form data-e2e="owner-confirmation-dynamic-form" [group]="formGroup" [model]="model" [layout]="layout"></dynamic-form>

    <p-button
      (click)="submitSetPasswordForm()"
      class="mt-m w-full xs:w-auto"
      variant="primary"
      data-e2e="confirmation-password-submit-button"
      [disabled]="confirmationLoading$ | async"
      [loading]="confirmationLoading$ | async"
      [myprofileSignupUiTrack]="{
        clickElementType: 'button',
        clickElementId: 'call_to_action',
        clickElementName: 'activate_pid',
        pageName: 'sign_up/confirm',
        eventAction: 'PAGMyPorsche_SignUpSetPW_Click'
      }"
    >
      <ng-container *ngIf="isLastStep(currentStep)">
        {{ lastStepLabel | translate }}
      </ng-container>
      <ng-container *ngIf="!isLastStep(currentStep)">
        {{ 'signup.confirm.owner.activateAccount.button.label' | translate }}
      </ng-container>
    </p-button>
  </form>
</div>

<myprofile-signup-ui-cancel-activation></myprofile-signup-ui-cancel-activation>
