/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core'
import { ActivatedRouteSnapshot } from '@angular/router'

@Injectable({
  providedIn: 'root',
})
export class LocaleRetrieveUrlParamsSignupService {
  retrieve(rootSnapshot: ActivatedRouteSnapshot): { locale: string; country: string } {
    let snapshot = rootSnapshot
    while (snapshot.firstChild?.params?.locale && snapshot.firstChild?.params?.country) {
      snapshot = snapshot.firstChild
    }

    if (snapshot.params) {
      const locale = snapshot.params.locale
      const country = snapshot.params.country
      return {
        locale: locale ? locale : '',
        country: country ? country : '',
      }
    }

    return {
      locale: '',
      country: '',
    }
  }
}
