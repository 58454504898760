/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core'
import { AbstractControl, AbstractControlOptions, UntypedFormControl, UntypedFormGroup } from '@angular/forms'
import { DynamicFormControlModel } from '../model/dynamic-form-control.model'
import { DynamicFormValueControlModel } from '../model/dynamic-form-value-control.model'
import { DYNAMIC_FORM_CONTROL_TYPE_GROUP, DynamicFormGroupModel } from '../model/form-group/dynamic-form-group.model'
import { DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP, DynamicCheckboxGroupModel } from '../model/checkbox/dynamic-checkbox-group.model'
import { DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX, DynamicCheckboxModel } from '../model/checkbox/dynamic-checkbox.model'
import { DYNAMIC_FORM_CONTROL_TYPE_INPUT, DynamicInputModel } from '../model/input/dynamic-input.model'
import { DYNAMIC_FORM_CONTROL_TYPE_PARAGRAPH, DynamicParagraphModel } from '../model/paragraph/dynamic-paragraph.model'
import { DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP, DynamicRadioGroupModel } from '../model/radio/dynamic-radio-group.model'
import { DYNAMIC_FORM_CONTROL_TYPE_SELECT, DynamicSelectModel } from '../model/select/dynamic-select.model'
import { DynamicFormValidationService } from './dynamic-form-validation.service'
import { DynamicFormModel } from '../model/dynamic-form.model'
import { DynamicPathable } from '../model/misc/dynamic-form-control-path.model'
import { DynamicFormHook, DynamicValidatorsConfig } from '../model/misc/dynamic-form-control-validation.model'
import { maskFromString, parseReviver } from '../utils/json.utils'
import { isString } from '../utils/core.utils'
import { DynamicFormComponentService } from './dynamic-form-component.service'

@Injectable({
  providedIn: 'root',
})
export class DynamicFormService {
  constructor(
    public componentService: DynamicFormComponentService,
    public validationService: DynamicFormValidationService,
  ) {}

  createFormGroup(
    formModel: DynamicFormModel,
    options: AbstractControlOptions | null = null,
    parent: DynamicPathable | null = null,
  ): UntypedFormGroup {
    const controls: { [controlId: string]: AbstractControl } = {}

    formModel.forEach((model) => {
      model.parent = parent

      switch (model.type) {
        case DYNAMIC_FORM_CONTROL_TYPE_GROUP:
        case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP:
          // eslint-disable-next-line no-case-declarations
          const groupModel = model as DynamicFormGroupModel
          // eslint-disable-next-line no-case-declarations
          const groupOptions = this.createAbstractControlOptions(groupModel.validators, groupModel.asyncValidators, groupModel.updateOn)

          controls[model.id] = this.createFormGroup(groupModel.group, groupOptions, groupModel)
          break

        default:
          // eslint-disable-next-line no-case-declarations
          const controlModel = model as DynamicFormValueControlModel<any>
          // eslint-disable-next-line no-case-declarations
          const controlState = { value: controlModel.value, disabled: controlModel.disabled }
          // eslint-disable-next-line no-case-declarations
          const controlOptions = this.createAbstractControlOptions(
            controlModel.validators,
            controlModel.asyncValidators,
            controlModel.updateOn,
          )

          controls[model.id] = new UntypedFormControl(controlState, controlOptions)
      }
    })

    return new UntypedFormGroup(controls, options)
  }

  getPathSegment(model: DynamicPathable): string {
    return (model as DynamicFormControlModel).id
  }

  getPath(model: DynamicPathable, join = false): string[] | string {
    const path = [this.getPathSegment(model)]
    let parent = model.parent

    while (parent) {
      path.unshift(this.getPathSegment(parent))
      parent = parent.parent
    }

    return join ? path.join('.') : path
  }

  findById(id: string, formModel: DynamicFormModel): DynamicFormControlModel | null {
    let result = null

    const findByIdFn = (modelId: string, groupModel: DynamicFormModel): void => {
      for (const controlModel of groupModel) {
        if (controlModel.id === modelId) {
          result = controlModel
          break
        }

        if (controlModel instanceof DynamicFormGroupModel) {
          findByIdFn(modelId, (controlModel as DynamicFormGroupModel).group)
        }
      }
    }

    findByIdFn(id, formModel)

    return result
  }

  findModelById<T extends DynamicFormControlModel>(id: string, formModel: DynamicFormModel): T | null {
    return this.findById(id, formModel) as T
  }

  findControlByModel<T extends AbstractControl>(model: DynamicFormControlModel, group: UntypedFormGroup): T | null {
    return group.root.get(this.getPath(model, true)) as T
  }

  fromJSON(json: string | object[]): DynamicFormModel | never {
    const formModelJSON = isString(json) ? JSON.parse(json, parseReviver) : json
    const formModel: DynamicFormModel = []

    formModelJSON.forEach((model: any) => {
      const layout = model.layout || null

      switch (model.type) {
        case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX:
          formModel.push(new DynamicCheckboxModel(model, layout))
          break

        case DYNAMIC_FORM_CONTROL_TYPE_CHECKBOX_GROUP:
          model.group = this.fromJSON(model.group) as DynamicCheckboxModel[]
          formModel.push(new DynamicCheckboxGroupModel(model, layout))
          break

        case DYNAMIC_FORM_CONTROL_TYPE_GROUP:
          model.group = this.fromJSON(model.group)
          formModel.push(new DynamicFormGroupModel(model, layout))
          break

        case DYNAMIC_FORM_CONTROL_TYPE_INPUT:
          // eslint-disable-next-line no-case-declarations
          const inputModel = model as DynamicInputModel

          if (inputModel.mask !== null) {
            if (!(inputModel.mask instanceof Function)) {
              inputModel.mask = maskFromString(inputModel.mask as string)
            }
          }

          formModel.push(new DynamicInputModel(model, layout))
          break

        case DYNAMIC_FORM_CONTROL_TYPE_RADIO_GROUP:
          formModel.push(new DynamicRadioGroupModel(model, layout))
          break

        case DYNAMIC_FORM_CONTROL_TYPE_SELECT:
          formModel.push(new DynamicSelectModel(model, layout))
          break

        case DYNAMIC_FORM_CONTROL_TYPE_PARAGRAPH:
          formModel.push(new DynamicParagraphModel(model, layout))
          break

        default:
          throw new Error(`unknown form control model type defined on JSON object with id "${model.id}"`)
      }
    })

    return formModel
  }

  detectChanges(): void {
    for (const form of this.componentService.getForms()) {
      form.markForCheck()
      form.detectChanges()
    }
  }

  private createAbstractControlOptions(
    validatorsConfig: DynamicValidatorsConfig | null = null,
    asyncValidatorsConfig: DynamicValidatorsConfig | null = null,
    updateOn: DynamicFormHook | null = null,
  ): AbstractControlOptions {
    return {
      asyncValidators: asyncValidatorsConfig !== null ? this.validationService.getAsyncValidators(asyncValidatorsConfig) : null,
      validators: validatorsConfig !== null ? this.validationService.getValidators(validatorsConfig) : null,
      updateOn: updateOn !== null && this.validationService.isFormHook(updateOn) ? updateOn : DynamicFormHook.Change,
    }
  }
}
