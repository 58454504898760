/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { browser } from './browser'
import { OneGaData } from './one-ga-types'

export type OneGaManagerData = {
  applicationDefault?: OneGaData
}

export function getOneGaManagerData() {
  if (!browser.getDocument().oneGaManager) {
    browser.getDocument().oneGaManager = {}
  }
  return browser.getDocument().oneGaManager
}

export function getTrackingLayer() {
  if (!browser.getWindow().trackingLayer) {
    browser.getWindow().trackingLayer = []
  }
  return browser.getWindow().trackingLayer
}

export function getGtmDataLayer() {
  if (!browser.getWindow().oneGa) {
    browser.getWindow().oneGa = []
  }
  return browser.getWindow().oneGa
}

export function getPagDataLayer() {
  if (!browser.getWindow().pagData) {
    browser.getWindow().pagData = []
  }
  return browser.getWindow().pagData
}
