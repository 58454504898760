/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core'
import { BehaviorSubject, filter, Observable, switchMap } from 'rxjs'
import { map, take } from 'rxjs/operators'
import { CookieConsentConfig } from './cookie-consent-config'
import { CookieConsentServiceName } from './cookie-consent-typings'
import { UscCookieConsentStatusProviderService } from './cookie-consent-status-provider.service'

@Injectable()
/**
 * Takes care of consent handling initialization and delegation to the correct submodules (GDPR, CCPA, ...).
 *
 * The CookieConsentService is meant for consumption by client applications that does not reveal any of the inner
 * workings (like initialization and cookie banner handling), while the CookieConsentImplementationService is meant to be used
 * within AngularCommon.
 */
export class CookieConsentImplementationService {
  private readonly initialized$ = new BehaviorSubject(false)

  constructor(
    private readonly config: CookieConsentConfig,
    private readonly cookieConsentStatusProvider: UscCookieConsentStatusProviderService,
  ) {}

  isConsentGiven(serviceName: CookieConsentServiceName): Observable<boolean> {
    // Delay emission of values until initialization is complete
    return this.initialized$.pipe(
      filter(Boolean),
      take(1),
      switchMap(() => this.cookieConsentStatusProvider.isConsentGiven(serviceName)),
    )
  }

  async init(urlCountry: string): Promise<void> {
    await this.cookieConsentStatusProvider.init()
    this.initialized$.next(true)
  }

  getConfig(): CookieConsentConfig {
    return this.config
  }

  getCookiePolicyUrl(): Observable<string> {
    // Delay emission of values until initialization is complete
    return this.initialized$.pipe(
      filter(Boolean),
      take(1),
      map(() => this.cookieConsentStatusProvider.getCookiePolicyUrl()),
    )
  }

  isConsentRequired() {
    return this.cookieConsentStatusProvider.isConsentRequired()
  }

  openCookieInformationModal(consentSystemName: CookieConsentServiceName) {
    return this.cookieConsentStatusProvider.openCookieInformationModal(consentSystemName)
  }

  updateConsent(consentSystemName: CookieConsentServiceName) {
    this.cookieConsentStatusProvider.updateConsent(consentSystemName)
  }

  getConsentDisplayName(consentSystemName: CookieConsentServiceName) {
    return this.cookieConsentStatusProvider.getConsentDisplayName(consentSystemName)
  }
}
