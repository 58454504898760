/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core'
import { Subject } from 'rxjs'

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private readonly STANDARD_LABEL_KEY = 'signup.loadingIndicator.text'

  private _state: Subject<boolean> = new Subject<boolean>()
  private _labelKey: Subject<string> = new Subject<string>()

  public state = this._state.asObservable()
  public labelKey = this._labelKey.asObservable()

  setLoadingState(state: boolean, description: string = this.STANDARD_LABEL_KEY) {
    this._state.next(state)
    this._labelKey.next(description)
  }
}
