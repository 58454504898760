/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { RegistrationType } from '../../../app/enums/registration-type'

const baseModelNames = ['718', '911', 'cayenne', 'cayman', 'macan', 'panamera', 'taycan']

function getRandomBaseModel() {
  const randomIndex = Math.floor(Math.random() * baseModelNames.length);
  return baseModelNames[randomIndex];
}

export const legalConditionsData = [
  {
    ciamVersion: 'PorscheConnectTAC/KR/ko_KR/v2.0',
    shopVersion: 'v2.0',
    type: 'TAC',
    linkPDF:
      'https://preview-connect-store-static01.porsche.com/medias/TC-Store-B2.5-SK-en-20200129-TAC-TestKorean-KR.pdf?context=bWFzdGVyfGFzc2V0c3wyMDAzOTR8YXBwbGljYXRpb24vcGRmfGFzc2V0cy9oN2YvaDJmLzg4MzMwMTQ5MjMyOTQucGRmfDgzOWI2ODFkMTI2ZDYwN2JiMzM5NmE4ZWJiYTZlNjllOWI2MzdmNmE2YjFiOGJjZTBiNzM4OTQ5MThmOGUyN2Q',
    linkExt: 'https://preview-connect-store2.porsche.com/kr/ko/t/termsandconditions?reducedHeaderFooter=true',
  },
  {
    ciamVersion: 'PorscheConnectDPG/KR/ko_KR/v1.0',
    shopVersion: 'v1.0',
    type: 'DPG',
    linkPDF:
      'https://preview-connect-store-static01.porsche.com/medias/LN-Personal-information-Store-20200129-DPG-TestKorean-KR.pdf?context=bWFzdGVyfGFzc2V0c3w4NzAyNnxhcHBsaWNhdGlvbi9wZGZ8YXNzZXRzL2hlYi9oYjAvODgzMzAxNDMzMzQ3MC5wZGZ8M2M4YTk4YTcxMmE5OTMxNmNmODlkOTc3YzA2MzhkNmRiY2Y1ZWFkYmNiYWEwZmIwNDRhMzY0YzM0NTkxNjI1Ng',
    linkExt: 'https://preview-connect-store2.porsche.com/kr/ko/t/privacy?reducedHeaderFooter=true',
  },
  {
    ciamVersion: 'PorscheConnectDPG_DC/KR/ko_KR/v1.0',
    shopVersion: 'v1.0',
    type: 'DPG_DC',
    linkPDF: '',
    linkExt: 'https://preview-connect-store2.porsche.com/kr/ko/t/privacyconsent?reducedHeaderFooter=true',
  },
  {
    ciamVersion: 'PorscheConnectDPG_2/KR/ko_KR/v1.0',
    shopVersion: 'v1.0',
    type: 'DPG_2',
    linkPDF:
      'https://preview-connect-store-static01.porsche.com/medias/LN-Personal-information-to-third-parties-Store-20200129-DPG2-TestKorean-KR.pdf?context=bWFzdGVyfGFzc2V0c3w4MTk1MXxhcHBsaWNhdGlvbi9wZGZ8YXNzZXRzL2gzZi9oODkvODgzMzAxMjQzMjkyNi5wZGZ8ZWMxOTc2N2U4ZGUwZjA0OWEwNTk4Mjc1ZGU3NmNmMjBiODM0OTIxYzI0ZDI1YzdlYzJhODBkMGQ0MWRmNzhjNg',
    linkExt: 'https://preview-connect-store2.porsche.com/kr/ko/t/privacy2?reducedHeaderFooter=true',
  },
  {
    ciamVersion: 'PorscheConnectDPG_3/KR/ko_KR/v2.3',
    shopVersion: 'v2.3',
    type: 'DPG_3',
    linkPDF:
      'https://preview-connect-store-static01.porsche.com/medias/LN-Cross-border-transfer-Store-20200129-DPG3-TestKorean-KR.pdf?context=bWFzdGVyfGFzc2V0c3w4OTQ0MXxhcHBsaWNhdGlvbi9wZGZ8YXNzZXRzL2hjZi9oYzIvODgzMzAxMzgwOTE4Mi5wZGZ8MDRmMjVlZTQ4YTIzM2JkZDY5YWZhNGE0OTZmZDJkMTYwNjNmNjZmZjM1N2MyNDhjNWRkMjcxZGJkZTFjYWRjZA',
    linkExt: 'https://preview-connect-store2.porsche.com/kr/ko/t/privacy3?reducedHeaderFooter=true',
  },
]

export const ownerTransactionValidationData = {
  type: 'OWNER',
  ciamId: 'ajs6npnmvny23yc8',
  porscheId: 'myPorscheId@myprofile.id',
  needsSpin: true,
  vin: 'BPOPIDE3PP0000169',
  authOptions: [{ type: 'SMS', confirmed: false, hint: '+49******4721' }],
  vehicle: { isConnectable: true, baseModel: 'macan' },
  registrationType: RegistrationType.EMAIL,
}

export const vehicleTransactionValidationData = {
  type: 'VEHICLE',
  ciamId: 'ajs6npnmvny23yc8',
  porscheId: 'myPorscheId@myprofile.id',
  simplified: false,
  needsSpin: true,
  mobile: '*****123',
  trialAvailable: true,
  authOptions: [{ type: 'SMS', confirmed: false, hint: '+49******4721' }],
  vehicle: { isConnectable: true },
  registrationType: RegistrationType.EMAIL,
}

export const simplifiedVehicleTransactionValidationData = {
  type: 'VEHICLE',
  ciamId: 'ajs6npnmvny23yc8',
  porscheId: 'myPorscheId@myprofile.id',
  simplified: true,
  needsSpin: true,
  mobile: '*****123',
  trialAvailable: true,
  authOptions: [{ type: 'SMS', confirmed: false, hint: '+49******4721' }],
  vehicle: { isConnectable: true },
  registrationType: RegistrationType.EMAIL,
}

export const simplifiedVehicleTransactionValidationDataMobile = {
  type: 'VEHICLE',
  ciamId: 'ajs6npnmvny23yc8',
  porscheId: 'myPorscheId@myprofile.id',
  simplified: true,
  needsSpin: true,
  mobile: '*****123',
  trialAvailable: true,
  authOptions: [{ type: 'SMS', confirmed: false, hint: '+49******4721' }],
  vehicle: { isConnectable: true },
  registrationType: RegistrationType.MOBILE,
}

export const prospectTransactionValidationData = {
  type: 'PROSPECT',
  authOptions: [],
  ciamId: 'noshbwztssan0cws',
  porscheId: 'some-porsche-id@pid.com',
  needsSpin: false,
  registrationType: RegistrationType.EMAIL,
}

export const trackYourDreamTransactionValidationData = {
  type: 'TRACK_YOUR_DREAM',
  authOptions: [],
  ciamId: 'noshbwztssan0cws',
  porscheId: 'some-porsche-id@pid.com',
  needsSpin: false,
  registrationType: RegistrationType.EMAIL,
}

export const ownerMobileTransactionValidationData = {
  type: 'OWNER',
  ciamId: 'ajs6npnmvny23yc8',
  porscheId: '+491234564721',
  needsSpin: true,
  vin: 'BPOPIDE3PP0000169',
  authOptions: [{ type: 'SMS', confirmed: false, hint: '+49******4721' }],
  vehicle: { isConnectable: true },
  registrationType: RegistrationType.MOBILE,
}

export const secondaryTransactionValidationData = {
  type: 'SECONDARY',
  ciamId: 'ajs6npnmvny23yc8',
  porscheId: 'myPorscheId@myprofile.id',
  needsSpin: true,
  vin: 'BPOPIDE3PP0000169',
  authOptions: [{ type: 'SMS', confirmed: false, hint: '+49******4721' }],
  vehicle: { isConnectable: true },
  registrationType: RegistrationType.EMAIL,
}

export const secondaryTransactionValidationDataMobile = {
  type: 'SECONDARY',
  ciamId: 'ajs6npnmvny23yc8',
  porscheId: '+4920508480896',
  dateOfBirth: '10101991',
  needsSpin: true,
  vin: 'BPOPIDE3PP0000169',
  authOptions: [{ type: 'DOB', confirmed: false, hint: '*******' }],
  vehicle: { isConnectable: true },
  registrationType: RegistrationType.MOBILE,
}

export const secondaryVehicleTransactionValidationData = {
  type: 'SECONDARY_VEHICLE',
  ciamId: 'ajs6npnmvny23yc8',
  porscheId: 'myPorscheId@myprofile.id',
  needsSpin: true,
  vin: 'BPOPIDE3PP0000169',
  authOptions: [{ type: 'SMS', confirmed: false, hint: '+49******4721' }],
  vehicle: { isConnectable: true },
  registrationType: RegistrationType.EMAIL,
}

export const encryptionKeysData = {
  keys: [
    {
      kty: 'RSA',
      e: 'AQAB',
      use: 'enc',
      kid: 'cdaab3fc-0122-42d1-93c4-1eb3733138b2',
      n: 'mren3XUpYnh1Gh5QDZCrpKmSTuRkl-0u9qY6MVJwN67P_e5BI6m7l6ZmlSkjsb21rXpCp5n7wzGQeYZlq1hURwQcrfj2wxXmR6-iHrnWyg0lT4eL8tZOjtBVSI4tA4x8oVsol-f3jGVjgPZizPoSsKzkKw1yw40IvRAJq-XAdg42Ehoe1HxfLGBz7Km9FqFtFbi-xg6J7ZPEjMZruIqgQLod70P3xPV1NN5zqMyNTxsBY3TvLmVlq8IAZgXeo4ebd8-hcXkNs4SrYYpd-MVZBFU6-qYmEzT19iMLDoYvzXddfpu9nSIm2KIT_vI7i4GJXndLKLpp9PxO89S5mKRtxw',
    },
  ],
}

export const confirmationResponseData = {
  cookies: [],
  redirectUrl: 'http://localhost:4200/',
  serviceActivationAvailable: true,
}

export const organisationContactInformation = [
  {
    id: '4a180ee0-42c5-11e6-b1c1-9c0b544e6529',
    email: 'smartmobility@de.porsche.com',
    telephoneNumber: '+49 711 91175500',
    companyName: 'Contact Porsche',
    displayName: 'Porsche Connect Support Germany',
  },
]

export const featureTogglesData = [
  {
    featureName: 'register_with_mobile_number',
    active: true,
  },
]

export const verifyTransactionIdData = {
  tid: '_prospect',
  otp: 'non-connect',
}
