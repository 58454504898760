/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { DynamicOptionControlModel, DynamicOptionControlModelConfig } from '../dynamic-option-control.model'
import { DynamicFormControlLayout } from '../misc/dynamic-form-control-layout.model'
import { isBoolean, isFunction } from '../../utils/core.utils'

export const DYNAMIC_FORM_CONTROL_TYPE_SELECT = 'SELECT'

export interface DynamicSelectModelConfig<T> extends DynamicOptionControlModelConfig<T> {
  compareWithFn?: (o1: any, o2: any) => boolean
  filterable?: boolean
  multiple?: boolean
  placeholder?: string
  prefix?: string
  suffix?: string
}

export class DynamicSelectModel<T> extends DynamicOptionControlModel<T> {
  compareWithFn: (value1: any, value2: any) => boolean
  filterable: boolean
  multiple: boolean
  placeholder: string
  prefix: string | null
  suffix: string | null

  readonly type: string = DYNAMIC_FORM_CONTROL_TYPE_SELECT

  constructor(config: DynamicSelectModelConfig<T>, layout?: DynamicFormControlLayout) {
    super(config, layout)

    this.compareWithFn = isFunction(config.compareWithFn) ? config.compareWithFn : Object.is
    this.filterable = isBoolean(config.filterable) ? config.filterable : false
    this.multiple = isBoolean(config.multiple) ? config.multiple : false
    this.placeholder = config.placeholder || ''
    this.prefix = config.prefix || null
    this.suffix = config.suffix || null
  }

  select(...indices: number[]): void {
    this.value = this.multiple ? indices.map((index) => this.get(index).value) : this.get(indices[0]).value
  }
}
