/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { AfterViewInit, Directive, ElementRef, Input, OnDestroy } from '@angular/core'

/**
 * Use this Directive to automatically focus an element on page load.
 * Provide input value if you which to conditionally apply the autofocus (default: true)
 */
@Directive({
  selector: '[autoFocus]',
})
export class AutoFocusDirective implements AfterViewInit, OnDestroy {
  @Input() autoFocus = true

  private timeOut

  constructor(private elementRef: ElementRef) {}

  ngAfterViewInit(): void {
    if (this.autoFocus) {
      // Delay the focus to prevent flaky behaviour
      this.timeOut = setTimeout(() => {
        this.elementRef.nativeElement.focus()
      }, 100)
    }
  }

  ngOnDestroy() {
    if (this.timeOut) {
      clearTimeout(this.timeOut)
    }
  }
}
