<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->
<div class="flex flex-col h-lvh">
  @if (layout === Layout.DEFAULT_LAYOUT) {
    <myprofile-signup-ui-layout-default></myprofile-signup-ui-layout-default>
  } @else if (layout === Layout.PROSPECT_LAYOUT) {
    <myprofile-signup-ui-layout-prospect class="contents"></myprofile-signup-ui-layout-prospect>
  } @else if (layout === Layout.SUCCESS_LAYOUT) {
    <div class="flex flex-1 -mb-l">
      <myprofile-signup-ui-layout-success></myprofile-signup-ui-layout-success>
    </div>
  } @else if (layout === Layout.MAINTENANCE_LAYOUT) {
    <div class="overflow-hidden -mb-l">
        <myprofile-signup-ui-layout-maintenance></myprofile-signup-ui-layout-maintenance>
    </div>
  }
  <myprofile-signup-ui-footer class="mt-l" [ngClass]="{ 'hidden': layout === Layout.PROSPECT_LAYOUT }"
                              [footerLinks]="footerLinks"
                              [showFooterLinksChina]="showFooterLinkChina"
                              [showFooterLinksUS]="showFooterLinkUSA"
                              [languageChooserEnabled]="false"
                              (languageChanged)="languageChanged($event)">
  </myprofile-signup-ui-footer>
</div>
