/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core'
import {init as initFullStory, FullStory, isInitialized} from '@fullstory/browser'
import { ConfigService } from '../config/config.service'
import { environment } from '../../../environments/environment'
import { BehaviorSubject, Observable } from 'rxjs'
import { TransactionType } from '../../enums/transaction-type'
import { CookieConsentImplementationService } from '../cookie-consent/cookie-consent.service'
import { CookieConsentServiceName } from '../cookie-consent/cookie-consent-typings'

@Injectable({
  providedIn: 'root',
})
export class FullstoryService {
  private readonly _country: string
  private readonly _fullstoryReady$$ = new BehaviorSubject<boolean>(false)

  constructor(private readonly _uscConsentService: CookieConsentImplementationService, private readonly _configService: ConfigService) {
    this._country = this._configService.getUrlCountry()
  }

  get isFullstoryReady$(): Observable<boolean> {
    return this._fullstoryReady$$
  }

  public setupFullstory() {
    if (this._uscConsentService.isConsentRequired()) {
      this._uscConsentService.isConsentGiven(CookieConsentServiceName.FULLSTORY).subscribe((value) => {
        if (value === true) {
          this.init()
        } else {
          this.shutdown()
        }
      })
    } else {
      if (this._country && !this._configService.isCNDomain()) {
        this.init()
      } else {
        this.shutdown()
      }
    }
  }

  public setTransactionContext(transactionType: TransactionType) {
    FullStory('setProperties', {
      type: 'user',
      properties: {
        transactionType,
      },
    })
  }

  private init() {
    const { devMode, orgId } = environment.fullstory
      initFullStory(
      {
        orgId,
        devMode,
      },
      () => this._fullstoryReady$$.next(true),
    )
  }

  private shutdown() {
      if (isInitialized()) {
          FullStory('shutdown')
          this._fullstoryReady$$.next(false)
      }
  }
}
