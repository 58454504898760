/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Inject, Injectable } from '@angular/core'
import { DOCUMENT } from '@angular/common'

@Injectable({
  providedIn: 'root',
})
export class ScriptLoaderService {
  constructor(@Inject(DOCUMENT) private document: any) {}

  load(src: string, scriptAttributes: { [key: string]: string } = {}, async = true): Promise<Event> {
    return new Promise<Event>((res, rej) => {
      const firstScript = this.document.getElementsByTagName('script')[0]
      const scriptToAdd = this.document.createElement('script')
      scriptToAdd.async = async
      scriptToAdd.src = src
      scriptToAdd.addEventListener('load', (evt: Event) => res(evt))
      scriptToAdd.addEventListener('error', (evt: Event) => rej(evt))
      for (const key of Object.keys(scriptAttributes)) {
        scriptToAdd.setAttribute(key, scriptAttributes[key])
      }
      firstScript.parentNode.insertBefore(scriptToAdd, firstScript)
    })
  }

  loadInline(content: string, id?: string): void {
    const firstScript = this.document.getElementsByTagName('script')[0]
    const scriptToAdd = this.document.createElement('script')
    const inlineScript = this.document.createTextNode(content)
    if (id) {
      scriptToAdd.setAttribute('id', id)
    }
    scriptToAdd.appendChild(inlineScript)
    scriptToAdd.async = true
    firstScript.parentNode.insertBefore(scriptToAdd, firstScript)
  }
}
