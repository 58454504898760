/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core'
import { getPasswordStrength, PasswordStrength } from '../../../../utils/password.utils'

@Component({
  selector: 'password-strength-meter',
  templateUrl: './password-strength-meter.component.html',
  styleUrls: ['./password-strength-meter.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PasswordStrengthMeterComponent implements OnInit {
  @Output() strengthCriteriaChangedEvent = new EventEmitter()

  // -------------------------------------------------------------------------------------------------------------------
  // @ Getters for password textual strength indicators
  // -------------------------------------------------------------------------------------------------------------------
  get containsLowercaseAndUppercase(): boolean {
    return this.passwordStrength?.contains.includes('lowercase') && this.passwordStrength?.contains.includes('uppercase')
  }

  get containsSymbols(): boolean {
    return this.passwordStrength?.contains.includes('symbol')
  }

  get containsNumbers(): boolean {
    return this.passwordStrength?.contains.includes('number')
  }

  get hasMinLength(): boolean {
    return this.passwordStrength?.length >= 8
  }

  private readonly _numberOfStrengthMeterItems = 6
  private readonly _strengthClasses = ['too-weak', 'weak', 'medium', 'strong']

  private _password: string
  @Input() set password(newPassword: string) {
    this._password = newPassword
    this._updateStrengthMeter(this._password)
  }

  get password(): string {
    return this._password
  }

  @Input() passwordCriteriaVisible = false
  public passwordStrength: PasswordStrength
  public strengthItems = Array(this._numberOfStrengthMeterItems).fill(this._strengthClasses[0])

  // -------------------------------------------------------------------------------------------------------------------
  // @ CONSTRUCTOR
  // -------------------------------------------------------------------------------------------------------------------
  constructor(private _cdr: ChangeDetectorRef) {}

  // -------------------------------------------------------------------------------------------------------------------
  // @ LIFECYCLE-METHODS
  // -------------------------------------------------------------------------------------------------------------------
  async ngOnInit(): Promise<void> {
    await this._updateStrengthMeter(this.password)
  }

  // -------------------------------------------------------------------------------------------------------------------
  // @ PRIVATE METHODS
  // -------------------------------------------------------------------------------------------------------------------
  private async _updateStrengthMeter(password: string): Promise<void> {
    this.passwordStrength = getPasswordStrength(password)

    // id is a number and corresponds to 0: too weak, 1: weak, 2: medium and 3: strong
    if (this.passwordStrength.id === 0) {
      // In case the password is too weak/not filled we need x grey strength indicators
      this.strengthItems = Array(this._numberOfStrengthMeterItems).fill(this._strengthClasses[0])
    } else {
      // In case the password is > too weak we need the indicators based on the strength and fill the rest up with grey ones
      // As we've currently got 6 strength indicators and the scale of the pw strength is set to 0-3 we multiply by 2
      this.strengthItems = Array(this.passwordStrength.id * 2).fill(this._strengthClasses[this.passwordStrength.id])

      if (this.strengthItems.length < this._numberOfStrengthMeterItems) {
        const fillUp = this._numberOfStrengthMeterItems - this.strengthItems.length
        for (let i = 0; i < fillUp; i++) {
          this.strengthItems.push(this._strengthClasses[0])
        }
      }
    }

    this.strengthCriteriaChangedEvent.emit({
      containsLowercaseAndUppercase: this.containsLowercaseAndUppercase,
      containsSymbols: this.containsSymbols,
      containsNumbers: this.containsNumbers,
      hasMinLength: this.hasMinLength,
    })

    this._cdr.markForCheck()
  }

  // -------------------------------------------------------------------------------------------------------------------
  // @ PUBLIC METHODS
  // -------------------------------------------------------------------------------------------------------------------
  public displayPasswordCriteria() {
    this.passwordCriteriaVisible = true
    this._cdr.markForCheck()
  }
}
