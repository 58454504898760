/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { USC_DEFAULT_CONFIG, UscConfig } from './usc-config'

export class CookieConsentConfig {
  enable?: boolean
  usc?: UscConfig
}

export const COOKIE_CONSENT_DEFAULT_CONFIG: CookieConsentConfig = {
  enable: false,
  usc: { ...USC_DEFAULT_CONFIG },
}
