<!--
  ~ This code is protected by intellectual property rights.
  ~ Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
  ~ © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
  -->

<p-heading tag="h1" class="text-balance mt-static_l xs:mt-l">
  {{ 'signup.activateAccount.confirmIdentity.headline' | translate }}
</p-heading>
<div class="w-full">
  <div *ngIf="isSecondFactorDob">
    <myprofile-signup-ui-dob-confirm-identity
      [currentPageName]="currentPageName"
      [errorMessages]="messages"
    ></myprofile-signup-ui-dob-confirm-identity>
  </div>
  <div *ngIf="!isSecondFactorDob">
    <myprofile-signup-ui-sms-confirm-identity
      [mobile]="mobile"
      [currentPageName]="currentPageName"
      [messages]="messages"
      [messageState]="messageState"
    ></myprofile-signup-ui-sms-confirm-identity>
  </div>

  <myprofile-signup-ui-cancel-activation></myprofile-signup-ui-cancel-activation>

  <p-text class="mt-static_l" *ngIf="isAddVehicleLeanActivation">
    <span [innerHTML]="leanActivationText"></span>
  </p-text>
</div>
