/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import {
  confirmationResponseData,
  encryptionKeysData,
  featureTogglesData,
  legalConditionsData,
  organisationContactInformation,
  ownerMobileTransactionValidationData,
  ownerTransactionValidationData,
  prospectTransactionValidationData,
  secondaryTransactionValidationData,
  secondaryTransactionValidationDataMobile,
  secondaryVehicleTransactionValidationData,
  simplifiedVehicleTransactionValidationData,
  simplifiedVehicleTransactionValidationDataMobile,
  trackYourDreamTransactionValidationData,
  vehicleTransactionValidationData,
  verifyTransactionIdData,
} from './data'
import { BackendRoutes, SIGNUP_API_PREFIX } from '../../../app/constants'
import { http, HttpResponse, passthrough } from 'msw'

const getPath = (requestUrl: string) => `http://api-base.local/${SIGNUP_API_PREFIX}${requestUrl}`

export const signupServiceHandlers = [
  http.get(getPath(BackendRoutes.v1.PUBLIC__KEYS_PASSWORD), getPublicKeyForPasswordEncryptionResolver),
  http.post(getPath(BackendRoutes.v1.CONFIRMATION__SPIN), setSPINResolver),
  http.post(getPath(BackendRoutes.v1.CONFIRMATION__SECURE_CONFIRM), confirmAccountResolver),
  http.post(getPath(BackendRoutes.v1.CONFIRMATION__CONFIRM), confirmAccountResolver),
  http.get(getPath(BackendRoutes.v1.CONFIRMATION__AUTH), validateConfirmationDataResolver),
  http.get(getPath(BackendRoutes.v1.CONFIRMATION__SECURE_AUTH), validateSecureConfirmationDataResolver),
  http.post(getPath(BackendRoutes.v1.REGISTRATION__ACTION_RESEND), resendRegistrationEmailResolver),
  http.post(getPath(BackendRoutes.v1.REGISTRATION__ACTION_SECURE_RESEND), resendRegistrationEmailResolver),
  http.post(getPath(BackendRoutes.v1.REGISTRATION__REGISTER_ORGANISATION), createOrganisationUserResolver),
  http.post(getPath(BackendRoutes.v1.REGISTRATION__REGISTER), createUserResolver),
  http.get(getPath(BackendRoutes.v1.PUBLIC__LEGAL), getLegalConditionsResolver),
  http.get(getPath(BackendRoutes.v1.PUBLIC__ORGANISATION), getContactOrganisationResolver),
  http.post(getPath(BackendRoutes.v1.CONFIRMATION__OTP_REQUEST), requestOTPResolver),
  http.post(getPath(BackendRoutes.v1.CONFIRMATION__OTP_VERIFICATION), verifyOTPResolver),
  http.get(getPath(BackendRoutes.v1.PUBLIC__FEATURE_ALL), getFeatureTogglesResolver),
  http.get(getPath(BackendRoutes.v1.CONFIRMATION__TRANSACTION_ID), verifyOtpAndGetTransactionIdResolver),
  http.post(getPath(BackendRoutes.v1.CONFIRMATION__PROFILE_EMAIL_VERIFICATION), verifyEmailOtpAndTransactionIdResolver),
]

function getPublicKeyForPasswordEncryptionResolver() {
  return HttpResponse.json(encryptionKeysData)
}

function setSPINResolver() {
  return new HttpResponse(null, {
    status: 204,
  })
}

async function confirmAccountResolver({ request }) {
  const body = await request.json()
  const isNonConnect = body?.otps.includes('_non-connect')
  return HttpResponse.json({
    ...confirmationResponseData,
    serviceActivationAvailable: !isNonConnect,
  })
}

function resendRegistrationEmailResolver() {
  return new HttpResponse(null, {
    status: 200,
  })

  // for failure testing - Open Invitation not found
  // return new HttpResponse(
  //   JSON.stringify({
  //     errorCode: 'RESOURCE_NOT_FOUND_OPEN_INVITATIONS',
  //   }),
  //   { status: 404 },
  // )
}

function validateConfirmationDataResolver({ request }) {
  const url = new URL(request.url)
  const tid = url.searchParams.get('tid')
  const otp = url.searchParams.get('otp')

  let data
  switch (tid) {
    case '_owner':
      data = ownerTransactionValidationData
      break
    case '_prospect':
      data = prospectTransactionValidationData
      break
    case '_vehicle':
      data = vehicleTransactionValidationData
      return new HttpResponse(
        JSON.stringify({
          errorCode: 'UNAUTHORIZED',
          message: 'Login required',
        }),
        { status: 401 },
      )
    case '_sav':
      data = simplifiedVehicleTransactionValidationData
      break
    case '_sav_mobile':
      data = simplifiedVehicleTransactionValidationDataMobile
      break
    case '_secondary':
      data = secondaryTransactionValidationData
      break
    case '_secondary_mobile':
      data = secondaryTransactionValidationDataMobile
      break
    case '_secvehicle':
      data = secondaryVehicleTransactionValidationData
      return new HttpResponse(
        JSON.stringify({
          errorCode: 'UNAUTHORIZED',
          message: 'Login required',
        }),
        { status: 401 },
      )
    case '_tyd':
      data = trackYourDreamTransactionValidationData
      break
    case '_owner_mobile':
      data = ownerMobileTransactionValidationData
      break
    case '_alreadyConfirmed':
      return new HttpResponse(
        JSON.stringify({
          errorCode: 'BUSINESS_EXCEPTION_TRANSACTION_ALREADY_CONFIRMED_OR_ABORTED',
          message: 'BUSINESS_EXCEPTION_TRANSACTION_ALREADY_CONFIRMED_OR_ABORTED',
        }),
        { status: 409 },
      )
    default:
      return passthrough()
  }

  if (otp === '_non-connect') {
    data = {
      ...data,
      isPcc: false,
      needsSpin: false,
      vehicle: {
        ...data.vehicle,
        isConnectable: false,
      },
    }
  }

  return HttpResponse.json(data)
}

function validateSecureConfirmationDataResolver({ request }) {
  const url = new URL(request.url)
  const tid = url.searchParams.get('tid')
  const otp = url.searchParams.get('otp')

  let data
  switch (tid) {
    case '_vehicle':
      data = vehicleTransactionValidationData
      break
    case '_sav':
      data = simplifiedVehicleTransactionValidationData
      break
    case '_sav_mobile':
      data = simplifiedVehicleTransactionValidationDataMobile
      break
    case '_secvehicle':
      data = secondaryVehicleTransactionValidationData
      break
    default:
      return passthrough()
  }

  if (otp === '_non-connect') {
    data = {
      ...data,
      isPcc: false,
      needsSpin: false,
      vehicle: {
        ...data.vehicle,
        isConnectable: false,
      },
    }
  }

  return HttpResponse.json(data)
}

function createOrganisationUserResolver() {
  return new HttpResponse(null, {
    status: 200,
  })
}

function createUserResolver() {
  return new HttpResponse(null, {
    status: 200,
  })
}

function getLegalConditionsResolver() {
  return HttpResponse.json(legalConditionsData)
}

function getContactOrganisationResolver() {
  return HttpResponse.json(organisationContactInformation)
}

function requestOTPResolver() {
  return new HttpResponse(null, {
    status: 204,
  })
}

function verifyOTPResolver() {
  return new HttpResponse(null, {
    status: 200,
  })

  // for failure testing - Blocked OTP
  // return new HttpResponse(
  //   JSON.stringify({
  //     errorCode: 'BUSINESS_EXCEPTION_TRANSACTION_AUTHENTICATION_OPTION_TEMPORARILY_BLOCKED',
  //   }),
  //   { status: 409 },
  // )

  // for failure testing - invalid OTP
  // return new HttpResponse(
  //   JSON.stringify({
  //     errorCode: 'BUSINESS_EXCEPTION_TRANSACTION_FAILED_CODE_VERIFICATION',
  //   }),
  //   { status: 409 },
  // )
}

function getFeatureTogglesResolver() {
  return HttpResponse.json(featureTogglesData)
}

function verifyOtpAndGetTransactionIdResolver() {
  return HttpResponse.json(verifyTransactionIdData)

  // for failure testing - Transaction not found
  // return new HttpResponse(
  //   JSON.stringify({
  //     errorCode: 'RESOURCE_NOT_FOUND_TRANSACTION',
  //   }),
  //   { status: 404 },
  // )
}

function verifyEmailOtpAndTransactionIdResolver({ request }) {
  const url = new URL(request.url)
  const tid = url.searchParams.get('tid')

  switch (tid) {
    case '_generic':
      return new HttpResponse(
        JSON.stringify({
          errorCode: 'TOO_MANY_REQUESTS_EMAIL_VERIFICATION',
          message: 'TOO_MANY_REQUESTS_EMAIL_VERIFICATION',
        }),
        { status: 429 },
      )
    case '_expired':
      return new HttpResponse(
        JSON.stringify({
          errorCode: 'BUSINESS_EXCEPTION_EMAIL_VERIFICATION_EXPIRED',
          message: 'BUSINESS_EXCEPTION_EMAIL_VERIFICATION_EXPIRED',
        }),
        { status: 409 },
      )
    case '_alreadyConfirmed':
      return new HttpResponse(
        JSON.stringify({
          errorCode: 'BUSINESS_EXCEPTION_EMAIL_VERIFICATION_CONFIRMED_OR_ABORTED',
          message: 'BUSINESS_EXCEPTION_EMAIL_VERIFICATION_CONFIRMED_OR_ABORTED',
        }),
        { status: 409 },
      )
    case '_invalid':
      return new HttpResponse(
        JSON.stringify({
          fieldErrors: [
            {
              field: 'otp',
              message: 'VALIDATION_EXCEPTION_EMAIL_VERIFICATION_INVALID_OTP',
            },
          ],
        }),
        { status: 400 },
      )
    default:
      return new HttpResponse(null, {
        status: 204,
      })
  }
}
