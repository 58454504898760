/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

export interface CookieConsentModel {
  templateId: string
  consentStatus: boolean
  technicalName: string
  [key: string]: any
  dataProcessor: string
}

export const USC_CONSENT_CHANGED_EVENT = 'consent_changed'

export interface UscCustomEvent extends Event {
  detail?: {
    data?: {
      event: string
      name: string
      status: boolean
    }
  }
}
