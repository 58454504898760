/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { Injectable } from '@angular/core'
import {
  DynamicFormControlLayout,
  DynamicFormControlLayoutConfig,
  DynamicFormControlLayoutContext,
  DynamicFormControlLayoutPlace,
} from '../model/misc/dynamic-form-control-layout.model'
import { DynamicFormControlModel } from '../model/dynamic-form-control.model'
import { isObject, isString } from '../utils/core.utils'

export type DynamicFormLayout = { [id: string]: DynamicFormControlLayout }

@Injectable({
  providedIn: 'root',
})
export class DynamicFormLayoutService {
  findById(id: string, formLayout: DynamicFormLayout | null): DynamicFormControlLayout | null {
    if (isObject(formLayout)) {
      for (const key of Object.keys(formLayout)) {
        if (key === id) {
          return formLayout[key]
        }
      }
    }

    return null
  }

  findByModel(model: DynamicFormControlModel, formLayout: DynamicFormLayout | null): DynamicFormControlLayout | null {
    let controlLayout: DynamicFormControlLayout = null

    if (isObject(formLayout)) {
      for (const key of Object.keys(formLayout)) {
        key.split(',').forEach((substring) => {
          const selector = substring.trim()

          if (selector === model.id || selector === model.type) {
            controlLayout = formLayout[key]
          }
        })
      }
    }

    return controlLayout
  }

  getClass(
    layout: DynamicFormControlLayout | null | undefined,
    context: DynamicFormControlLayoutContext,
    place: DynamicFormControlLayoutPlace,
  ): string {
    // eslint-disable-next-line no-prototype-builtins
    if (isObject(layout) && layout.hasOwnProperty(context)) {
      const config = layout[context] as DynamicFormControlLayoutConfig

      // eslint-disable-next-line no-prototype-builtins
      if (config.hasOwnProperty(place)) {
        return config[place] as string
      }
    }

    return ''
  }

  getHostClass(layout: DynamicFormControlLayout | null | undefined): string {
    const keys: (keyof DynamicFormControlLayout)[] = ['element', 'grid']
    let cls = ''

    if (isObject(layout)) {
      keys.forEach((key) => {
        if (isObject(layout[key]) && isString(layout[key].host)) {
          cls = cls + ` ${layout[key].host}`
        }
      })
    }

    return cls
  }

  getElementId(model: DynamicFormControlModel): string {
    return model.id
  }
}
