/*
 * This code is protected by intellectual property rights.
 * Dr. Ing. h.c. F. Porsche AG owns exclusive rights of use.
 * © 2017-2024, Dr. Ing. h.c. F. Porsche AG.
 */

import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core'
import { CommonModule } from '@angular/common'
import { FormsModule, ReactiveFormsModule } from '@angular/forms'
import { PidxTooltipModule } from './pidx-tooltip/pidx-tooltip.module'
import { ContactBoxComponent } from './contact-box/contact-box.component'
import { TranslateModule } from '@ngx-translate/core'
import { PorscheDesignSystemModule } from '@porsche-design-system/components-angular'
import { NotificationBannerComponent } from './notification-banner/notification-banner.component'
import { LoadingIndicatorComponent } from './loading-indicator/loading-indicator.component'
import { DirectivesModule } from '../directive/directives.module'
import { RequestSmsCodeComponent } from './request-sms-code/request-sms-code.component'
import { SetPasswordComponent } from './set-password/set-password.component'
import { DynamicFormsCoreModule } from '@myprofile-signup-ui/myprofile-dynamic-forms'
import { SetSpinComponent } from './set-spin/set-spin.component'
import { AuthCallbackComponent } from './auth-callback/auth-callback.component'
import { RouterModule } from '@angular/router'
import { FriendlyCaptchaComponent } from './friendly-captcha/friendly-captcha.component'
import { ConfirmIdentityComponent } from './confirm-identity/confirm-identity.component'
import { DobConfirmIdentityComponent } from './confirm-identity/dob/dob-confirm-identity.component'
import { SmsConfirmIdentityComponent } from './confirm-identity/sms/sms-confirm-identity.component'
import { TimerLabelComponent } from './timer-label/timer-label.component'
import { CancelActivationComponent } from './activation-cancel/cancel-activation.component'
import { IMaskModule } from 'angular-imask'
import { ConfirmationIntroductionComponent } from './confirmation-introduction/confirmation-introduction.component';

const COMPONENTS = [
  ContactBoxComponent,
  NotificationBannerComponent,
  LoadingIndicatorComponent,
  RequestSmsCodeComponent,
  SetPasswordComponent,
  SetSpinComponent,
  AuthCallbackComponent,
  FriendlyCaptchaComponent,
  ConfirmIdentityComponent,
  DobConfirmIdentityComponent,
  SmsConfirmIdentityComponent,
  TimerLabelComponent,
  CancelActivationComponent,
  ConfirmationIntroductionComponent,
]

const MODULES = [PidxTooltipModule, TranslateModule, PorscheDesignSystemModule, DirectivesModule, DynamicFormsCoreModule]

@NgModule({
  declarations: [...COMPONENTS],
  imports: [IMaskModule, CommonModule, RouterModule, ReactiveFormsModule, FormsModule, ...MODULES],
  exports: [...COMPONENTS, ...MODULES],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class ComponentsModule {}
